import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  PersonService,
  SettingsService,
  StructuresService,
} from "@smartobjx/smart.connectors";
import { Perspective } from "@smartobjx/smart.objx.models";
import { environment } from "src/environments/environment";
import { AuthService } from "../authentication/auth.service";

export function factory() {
  return (
    _server: SettingsService,
    _structuresServer: StructuresService,
    _serverPerson: PersonService,
    _authService: AuthService
  ): Mediator => {
    return new Mediator(
      _server,
      _structuresServer,
      _serverPerson,
      _authService
    );
  };
}

@Injectable({
  providedIn: "root",
})
export default class Mediator {
  SetProfile: boolean;
  GetTokensforSettings(
    subscriberToken: string,
    ownerId: string
  ): Observable<any> {
    this.structuresServer.configuration.SubscriberToken = subscriberToken;
    this.structuresServer.configuration.POVToken = ownerId;
    return this.structuresServer.gettokenforsettings();
  }

  GetPerspectives(
    subscriberToken: string,
    ownerId: string
  ): Observable<Array<Perspective>> {
    this.structuresServer.configuration.SubscriberToken = subscriberToken;
    this.structuresServer.configuration.POVToken = ownerId;
    return this.structuresServer.getAllPerspectives();
  }

  GetNestedClients(ownerId: string, subscriberToken: string, perspectiveId) {
    this.structuresServer.configuration.SubscriberToken = subscriberToken;
    this.structuresServer.configuration.POVToken = ownerId;
    return this.structuresServer.findNestedClients(perspectiveId);
  }

  checkLoginAndGetName(ownerId: string, subscriberToken: string, perspective) {
    this.server.configuration.SubscriberToken = subscriberToken;
    this.server.configuration.POVToken = ownerId;
    this._serverPerson.configuration.SubscriberToken = subscriberToken;
    this._serverPerson.configuration.POVToken = ownerId;
    return this._serverPerson.checkLoginAndGetName(perspective);
  }
  ActiveMarketPlaceOffer(token, companyName) {
    let headerRequest = {};
    headerRequest["Content-Type"] = "application/json";
    let body = { marketOffer: token, companyName: companyName };
    return this._authService.httpClient.post(
      `${environment.SubcriptionService}/ResolveSettingsOffer/`,
      body,
      {
        withCredentials: null,
        headers: headerRequest,
      }
    );
  }

  constructor(
    private server: SettingsService,
    private structuresServer: StructuresService,
    private _serverPerson: PersonService,
    private _authService: AuthService
  ) {}
}
