import { Component, Input, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import Mediator from '../core-services/mediator/settings.mediator';
import { Configuration, Setting } from '@smartobjx/smart.objx.models';
import { ViewControllerService, ViewControllerMember } from '../core-services/view/ViewControllerService';
import { AuthService } from '../core-services/authentication/auth.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
    selector: 'distribution-specs',
    templateUrl: './distribution-specs.component.html',
    styleUrls: ['./distribution-specs.component.scss'],
})
export class DistributionSpecsComponent implements OnInit, OnDestroy {
    configurations: Configuration;
    updateField: Subject<any>;
    keyEvents: EventEmitter<string> = new EventEmitter();
    DistributionList: { Value: string; Count: number; Percent: number; }[];
    chart: { name: string; value: number; }[];
    chartData;
    loading = true;
    gridData: any[];
    ColumnMode = ColumnMode;

    constructor(
        private mediator: Mediator,
        public _dialog: MatDialog,
        private viewController: ViewControllerService,
        private fb: UntypedFormBuilder,
        private _authService: AuthService,
        private _info: MatSnackBar
    ) {
        this.minDate = new Date();
        this.advOptEnabled = false;
        this.pov = this._authService.getPOV();
    }  // #region Properties

    get model(): any {
        return this.i_Model;
    }

    availableTypes = ["String", "int", "boolean", "long"]

    @Input()
    set model(newModel: any) {
        this.i_Model = newModel;
        this.selectedIndex = -1;
    }

    get selectedIndex(): number {
        return this.i_SelectedIndex;
    }

    set selectedIndex(clickedItemIndex: number) {
        this.i_SelectedIndex = clickedItemIndex;
    }

    get descriptionHint(): string {
        return 'This text should be a description of the setting or group of <b>settings</b>. This text should help the administrators to understand what the value affect.'

    }
    get formIsDisabled(): boolean {
        return !this.form || (this.form.pristine && this.disableOrEnableStack.length === 0) || !this.form.valid;
    }
    get priorToParent(): boolean {
        const now = new Date();
        return now < this.minDate;
    }

    get startDate(): Date {
        const { Data } = this.view;
        return Data ? Data.startDate : undefined;
    }
    minDate: Date;
    initialDate: Date; 
    advOptEnabled: boolean;


    private get isUntouched(): boolean {
        return this.form.pristine && this.disableOrEnableStack.length == 0;
    }

    private i_startDate: Date = new Date();
    startDateForceInvalid: boolean = false;
    // #endregion

    // #region Data Elements
    private i_Model: Array<Setting>;
    private i_SelectedIndex: number;
    private form: UntypedFormGroup;
    private disableOrEnableStack: any[] = [];
    private pov: string;

    @Input() view: ViewControllerMember;
    get isLoading(): boolean { return this.view.Loading; };
    get isActive(): boolean { return this.view.Active; };
    get asUseCase(): boolean { return this.view.asUseCase(); };
    get versionDateData(): boolean { return this.view.Data && this.view.Data.versionDateData; };

    @ViewChild('nameInput', { static: false }) nameInputRef: any;
    private disableFocused: boolean = false;
    fixDateValue: string;
    @ViewChild('startDateMinVerification', { static: false }) startDateMinVerificationDialog: any;
 
    checkDateValues(date: Date) {
        return date >= this.minDate;
    }

    toggleAdvancedOptions() {
        this.advOptEnabled = !this.advOptEnabled;
    }


    private getType(record: any) {
        let type = (record as any).$type;
        if (!type) return 'undefined';
        if (type.includes('Rules.RuleSet,')) return 'ruleset';
        if (type.includes('Rules.Rule,')) return 'rule';
    }

    private getControl(name: string): UntypedFormControl {
        return this.form.get(name) as UntypedFormControl;
    }


    ngOnDestroy() {
    }

    ngOnInit() {

        this.loading = true;
        let listSetting = this.model as any;
        let listGuidOrg = listSetting.group.map(setting=> setting.OwnerID)
        this.mediator.GetOrganizationList(listGuidOrg).subscribe(orgs =>{
            this.loading = false;
            this.gridData = orgs
        } )
     //  this.DistributionList = [{ Value: "Master Card", Count: 3, Percent: 75, },
     //  { Value: "Herzog Card", Count: 1, Percent: 25, }]
     //  this.updateField = new Subject()
     //  let model = this.model as any;
     //  this.mediator.GetDistributionList(this.view.Parent.OwnerID).subscribe((distribution)=> { 
     //      this.chartData = distribution.map(function (element) {
     //          return { name: element.Value, value: element.Percent };
     //      })
     //      this.gridData = distribution
     //      this.loading = false;
     //      console.log(distribution)
     //  
     //  })
      //  this.chartData = this.DistributionList.map(function (element) {
      //      return { name: element.Value, value: element.Percent };
      //  })
    }
    ngOnChanges(changes: any): void {

    }
    columns = [{ prop: 'Name' }, { prop: 'OID' }];
  

    single: any[];
    views: any[] = [540, 400];

    // options
    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = true;
    showXAxisLabel: boolean = true;
    yAxisLabel: string = 'Value';
    showYAxisLabel: boolean = true;
    xAxisLabel: string = 'Percent';

    colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };


    onSelect(data): void {
        console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    }

    onActivate(data): void {
        console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    onDeactivate(data): void {
        console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }
}