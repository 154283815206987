import { Component, Input, OnInit, OnDestroy, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import Mediator from "../core-services/mediator/settings.mediator";
import {
  Configuration,
  AbstractConfiguration,
} from "@smartobjx/smart.objx.models";
import {
  ViewControllerService,
  ViewControllerMember,
} from "../core-services/view/ViewControllerService";
import { SimpleDialogComponent } from "../simple-dialog/simple-dialog.component";
import { CustomValidator } from "../shared/validation";
import { Tools } from "../shared/Tools";
import { AuthService } from "../core-services/authentication/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "configuration-editor",
  templateUrl: "./configuration-editor.component.html",
  styleUrls: ["./configuration-editor.component.scss"],
})
export class ConfigurationEditorComponent implements OnInit, OnDestroy {
  ComponentName: string = "ConfigurationEditorComponent";
  constructor(
    private mediator: Mediator,
    public _dialog: MatDialog,
    private viewController: ViewControllerService,
    private fb: UntypedFormBuilder,
    private _authService: AuthService,
    private _info: MatSnackBar
  ) {
    this.minDate = new Date();
    this.advOptEnabled = false;
    this.pov = this._authService.getPOV();
  } // #region Properties

  get model(): AbstractConfiguration {
    return this.i_Model;
  }

  @Input()
  set model(newModel: AbstractConfiguration) {
    this.i_Model = newModel;
    this.selectedIndex = -1;
  }

  get selectedIndex(): number {
    return this.i_SelectedIndex;
  }

  set selectedIndex(clickedItemIndex: number) {
    this.i_SelectedIndex = clickedItemIndex;
  }

  get formIsDisabled(): boolean {
    return (
      !this.form ||
      (this.form.pristine && this.deleteStack.length === 0) ||
      !this.form.valid
    );
  }
  get priorToParent(): boolean {
    const now = new Date();
    return now < this.minDate;
  }

  // the parent date (will be used as minDate)
  get startDate(): Date {
    const { Data } = this.view;
    return Data ? Data.startDate : undefined;
  }
  minDate: Date;
  initialDate: Date; // the current version date
  advOptEnabled: boolean;

  get isNew(): boolean {
    return !!this.model && !this.model.OID;
  }
  private get isUntouched(): boolean {
    return this.form.pristine && this.deleteStack.length == 0;
  }

  private i_startDate: Date = new Date();
  startDateForceInvalid: boolean = false;
  // #endregion

  // #region Data Elements
  private i_Model: AbstractConfiguration;
  private i_SelectedIndex: number;
  private form: UntypedFormGroup;
  private deleteStack: any[] = [];
  private pov: string;

  @Input() view: ViewControllerMember;
  get isLoading(): boolean {
    return this.view.Loading;
  }
  get isActive(): boolean {
    return this.view.Active;
  }
  get asUseCase(): boolean {
    return this.view.asUseCase();
  }
  get asApplication(): boolean {
    return this.view.asApplication();
  }
  get asProfile(): boolean {
    return this.view.asProfile();
  }

  get newApplication(): boolean {
    return this.view.newApplication();
  }
  get newProfile(): boolean {
    return this.view.newProfile();
  }

  get versionDateData(): boolean {
    return this.view.Data && this.view.Data.versionDateData;
  }

  @ViewChild("nameInput", { static: false }) nameInputRef: any;
  private disableFocused: boolean = false;
  fixDateValue: string;
  @ViewChild("startDateMinVerification", { static: false })
  startDateMinVerificationDialog: any;
  // #endregion

  // #region Event Emitters
  // #endregion
  // #region Event Handlers

  // #region public
  onSaveModel() {
    if (this.isUntouched) return;
    const date = this.i_startDate;
    if (this.checkDateValues(date)) {
      let model = this.mergeFormToModel();
      const resetForm = () => {
       // this.form.reset();
        this.deleteStack = [];
        this.form.markAsPristine();
      };

      if (this.deleteStack.length) {
        this.mediator.saveConfigurationWithDeleteChildren(
          this.model,
          this.view,
          model,
          date as Date,
          this.deleteStack,
          resetForm,
          this.viewController
        );
      } else {
        if (this.newApplication || this.asApplication) {
          this.mediator.createApplicationFromConfig(
            this.view,
            model,
            resetForm,
            this.newApplication,
            this.viewController
          );
        } else if (this.newProfile && this.asProfile) {
          this.mediator.createProfileFromConfig(
            this.view,
            model,
            resetForm,
            this.newProfile,
            this.viewController
          );
        } else if (this.viewController.SetProfile) {
          this.mediator.saveConfigurationProfile(
            this.model,
            model,
            this.view,
            resetForm,
            this.viewController
          );
        } else {
          this.mediator.saveConfiguration(
            this.model,
            model,
            this.view,
            resetForm,
            this.viewController
          );
        }
      }
    } else {
      this.openDialog(this.startDateMinVerificationDialog);
    }
  }

  openDialog(el: any, options: any = null) {
    return this._dialog.open(el, options);
  }
  checkStartDateBefore(fn: (startDate: Date) => void) {
    const startDate = this.i_startDate;
    if (!startDate) {
      this.fireStartDateError();
      return;
    }

    const now = new Date();
    const version = startDate > now ? startDate : now;

    fn(version);
  }
  // #region from view

  appendSetting(settingModel = null) {
    this.checkStartDateBefore((startDate) => {
      this.selectedIndex = -1;
      if (settingModel != null && settingModel != undefined) {
        this.mediator.editConfiguration(
          settingModel,
          this.view,
          this.model,
          startDate,
          this,
          this.versionDateData,
          this.viewController
        );
      } else {
        this.mediator.newSetting(
          this.view,
          this.model,
          startDate,
          this,
          this.viewController
        );
      }
    });
  }

  appendAbstract(abstractModel) {
    let type = abstractModel.$type as string;
    if (type.includes("Settings.Configuration")) {
      this.appendConfig(abstractModel);
    } else if (type.includes("Settings.Setting")) {
      this.appendSetting(abstractModel);
    } else if (type.includes("Settings.SecureSetting")) {
      this.appendSetting(abstractModel);
    } else {
      throw "undefined type for save";
    }
  }

  appendConfig(configurationModel: Configuration = null) {
    this.checkStartDateBefore((startDate) => {
      this.selectedIndex = -1;
      if (configurationModel != null && configurationModel != undefined) {
        this.mediator.editConfiguration(
          configurationModel,
          this.view,
          this.model,
          startDate,
          this,
          this.versionDateData,
          this.viewController
        );
      } else {
        this.mediator.newConfiguration(
          this.view,
          this.model,
          startDate,
          this,
          this.viewController
        );
      }
    });
  }

  onVersions(record: any, date: Date, versionDates: any[]) {
    this.view;
    this.mediator.showConfigVersions(
      record,
      date,
      versionDates,
      () => (this.selectedIndex = -1),
      this.viewController,
      this.view
    );
    this.updateSelected(record);
  }

  fireStartDateError() {
    this._info.open("Start Date must be valid before this action", "", {
      duration: 3000,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: "info-warn",
    });
  }

  onDisableOrEnable(config: AbstractConfiguration) {
    this.updateSelected(config);
    this.openDialog(SimpleDialogComponent, {
      panelClass: "smart-objx",
      autoFocus: false,
      data: {
        title: "Attention",
        titleClass: "warning",
        matIcon: "warning_amber",
        button1Text: `Yes, delete it`,
        content:
          `Do you really want to delete the ` +
          this.getType(config) +
          ` "<b>${config.Name}</b>" ?`,
      },
    })
      .afterClosed()
      .toPromise()
      .then((action) => {
        (document.activeElement as any).blur(); 
        this.selectedIndex = -1;
        if (action) {
          this.viewController.clearBy(this.model);
          this.stackToDelete({ configuration: config });
        }
      });
  }

  onDelete(config: AbstractConfiguration) {
    this.updateSelected(config);
    this.openDialog(SimpleDialogComponent, {
      panelClass: "smart-objx",
      autoFocus: false,
      data: {
        title: "Are you sure?",
        titleClass: "error",
        matIcon: "delete_forever",
        content:
          "Do you really want to want to disable the " +
          this.getType(config) +
          ` "<b>${config.Name}</b>" ?` +
          "\r\nThis process cannot be undone.",
      },
    })
      .afterClosed()
      .toPromise()
      .then((action) => {
        (document.activeElement as any).blur();
        if (action) {
          let primChildren = (this.model as any).PrimChildren,
            selectedIndex = primChildren.indexOf(config);
          primChildren[selectedIndex].Disabled = true;
          this.stackToDelete({ configuration: config });

          this.viewController.clearBy(this.model);
        }
      });
  }

  onEnable(config: AbstractConfiguration) {
    this.updateSelected(config);
    this.openDialog(SimpleDialogComponent, {
      panelClass: "smart-objx",
      autoFocus: false,
      data: {
        title: "Are you sure?",
        titleClass: "error",
        matIcon: "delete_forever",
        content:
          "Do you really want to want to enable the " +
          this.getType(config) +
          ` "<b>${config.Name}</b>" ?` +
          "\r\nThis process cannot be undone.",
      },
    })
      .afterClosed()
      .toPromise()
      .then((action) => {
        (document.activeElement as any).blur();
        if (action) {
          let primChildren = (this.model as any).PrimChildren,
            selectedIndex = primChildren.indexOf(config);
          primChildren[selectedIndex].Disabled = false;
          this.stackToDelete({ configuration: config });

          this.viewController.clearBy(this.model);
        }
      });
  }

  onChanges() {
    (this.form as any).pristine = false;
  }
  checkDateValues(date: Date) {
    return date >= this.minDate;
  }

  toggleAdvancedOptions() {
    this.advOptEnabled = !this.advOptEnabled;
  }

  simpleDate(date: any) {
    return Tools.dateToString(date);
  }
  markStartDateAsDirty() {
    this.startDateForceInvalid = true;
  }
  fixStartDateAndSave() {
    this.i_startDate = this.priorToParent ? this.minDate : new Date();
    this.onSaveModel();
  }
  fixMinDate(date: Date) {
    const diff = this.minDate.getTime() - date.getTime();
    if (diff > 0 && diff < 100) {
      this.minDate = date;
    }
  }
  // #endregion

  // #endregion

  // #region private
  private checkStartDate() {
    const { startDate } = this;
    if (startDate) {
      this.minDate = startDate;
    }
  }
  private updateSelected(rule: AbstractConfiguration) {
    this.selectedIndex = (this.model as any).PrimChildren.indexOf(rule);
  }

  private getType(record: any) {
    let type = (record as any).$type;
    if (!type) return "undefined";
    if (type.includes("Settings.Configuration,")) return "configuration";
    if (type.includes("Settings.Setting,")) return "setting";
  }

  private getControl(name: string): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  private mergeFormToModel() {
    let model = Object.assign({}, this.model as any);
    model.Name = this.form.value.name;
    model.Description = this.form.value.description;
    model.Version = this.i_startDate;
    model.NotReplaceable = this.form.value.notReplaceable;

    return model;
  }

  private stackToDelete(data: any) {
    let filter = this.deleteStack.filter(
      (o) => o.configuration === data.configuration
    );
    if (filter.length) {
      const i = this.deleteStack.indexOf(filter[0]);
      this.deleteStack.splice(i, 1);
    } else {
      this.deleteStack.push(data);
    }
  }
  private showWarning() {
    this.openDialog(SimpleDialogComponent, {
      panelClass: "smart-objx",
      autoFocus: false,
      data: {
        title: "Attention",
        titleClass: "warning",
        matIcon: "warning_amber",
        button1Text: "Yes, close the panel",
        button2Text: "No, keep the panel open",
        button1Color: "primary",
        content:
          "Are you sure you want to close this panel?" +
          "\r\nThe changes won´t be saved.",
      },
    })
      .afterClosed()
      .toPromise()
      .then((action) => {
        if (action) {
          this.view.close(true);
        }
      });
  }
  private registerEvents() {
    this.view.Events.onClose$ = () => {
      if (!this.isUntouched) {
        this.showWarning();
      }
      return this.isUntouched;
    };
  }
  // #endregion
  // #endregion

  // #region Construction & Finalization

  ngOnDestroy() {}

  ngOnInit() {
    this.checkStartDate();
    this.registerEvents();

    let model = this.model;
    if (typeof model.Version === "string" && !!model.Version) {
      model.Version = CustomValidator.ensureDate(model.Version);
    }
    this.form = this.fb.group({
      name: [
        {
          value: this.replaceDefaultName(model),
          disabled: !this._authService.CanAdd,
        },
        Validators.required,
      ],
      description: [this.replaceDefaultDescription(model)],
      notReplaceable: [model.NotReplaceable || false, [Validators.required]],
    });
    this.i_startDate = new Date(model.Version.getTime());
    this.initialDate = model.Version;
    this.fixMinDate(model.Version);
  }

  replaceDefaultDescription(model: AbstractConfiguration): string {
    return Tools.isEmptyGuid(model.OID) && this.asApplication
      ? "Describes what the application element controls."
      : model.Description;
  }
  replaceDefaultName(model: AbstractConfiguration) {
    return Tools.isEmptyGuid(model.OID) && this.asApplication
      ? "Unnamed Application"
      : model.Name;
  }

  ngOnChanges(changes: any): void {
    this.checkStartDate();
    if (changes.model && !changes.model.firstChange) {
      let model = changes.model.currentValue;

      if (typeof model.Version === "string" && !!model.Version) {
        model.Version = CustomValidator.ensureDate(model.Version);
      }
      this.form.patchValue({
        name: model.Name,
        description: model.Description,
        notReplaceable: _.isNil(model.NotReplaceable)? false:model.NotReplaceable ,
      });
      this.i_startDate = new Date(model.Version.getTime());
      this.initialDate = model.Version;
    }

    // focus name
    if (
      changes.isLoading &&
      changes.isLoading.currentValue == false &&
      !this.disableFocused
    ) {
      setTimeout(() => {
        this.nameInputRef.nativeElement.focus();
        this.disableFocused = true;
      }, 0);
    }
  }
  updateConfiguration() {
    this.mediator.removeCacheItem(this.model.OID).subscribe(() => {
      this.mediator
        .updateConfiguration(
          this.model,
          this.viewController,
          this.versionDateData,
          this.view
        )
        .subscribe((model) => {
          this.model = model as Configuration;
          this.view.Model = model as Configuration;
          let parentComponent = this.viewController.getParentComponent(model);
          if (!_.isNil(parentComponent)) {
            parentComponent.updateConfigurationList();
          } else {
            this.viewController.updateRootForReplace.next();
          }
        });
    });
  }

  showStats(model) {
    this.mediator.newStats(this.view, model, this, true, this.viewController);
  }

  // #endregion
}
