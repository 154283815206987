<mat-card appearance="outlined" class="full-height mat-main-card" [class.mat-card-raised]="isActive">
  <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>

  <mat-card-title class="overline">DISTRIBUTION SPEC {{model.Value}}</mat-card-title>
  <mat-icon class="close" (click)="this.view.close()">close</mat-icon>
  <ng-container>
    <mat-card-content>
     
      <ngx-datatable [rows]="gridData" [loadingIndicator]="loading" [columns]="columns" class="material striped" [columnMode]="ColumnMode.force" [headerHeight]="50"
      [footerHeight]="50" [rowHeight]="50">
      </ngx-datatable>

    </mat-card-content>
    <!-- footer -->



  </ng-container>



</mat-card>