export default class SimpleEvent {
  private promise: Promise<null>;
  private reject: (reason?: any) => void;
  public resolve: (value?: null) => void;;
  public then(callback: (valor: null) => void){
      return this.promise.then(callback);
  }
  constructor() {
      this.promise = new Promise((done, err)=> {
          this.reject = err
          this.resolve = (value: null) => done(value)
      })
  }
}
