import { ApplicationServiceConfiguration, ProfilesServiceConfiguration, RulesServiceConfiguration } from '@smartobjx/smart.connectors';
import { AuthService } from './authentication/auth.service';
import { environment } from '../../environments/environment';

export function applicationConfigurationService() {
  return (_authenticationService: AuthService): ApplicationServiceConfiguration => {
    return new ApplicationServiceConfiguration({
      httpClient: _authenticationService.httpClient,
      basePath: environment.settingsServiceBasePath,
      SubscriberToken: _authenticationService.getTokenId(),
      POVToken: _authenticationService.getPOV()
    });
  };
}