
import { Injectable } from '@angular/core';


import SimpleEvent from 'src/app/shared/SimpleEvent.class';
import { AuthService } from '../authentication/auth.service';
import { Subject, Subscriber } from 'rxjs';


import { AbstractRule, Rule, AbstractConfiguration, Configuration, Person, Setting } from '@smartobjx/smart.objx.models';

import EventData from 'src/app/shared/EventData.class';
import EventBusService from 'src/app/shared/EventBusService';
import * as _ from "lodash";
import { CustomValidator } from 'src/app/shared/validation';

export class ViewControllerService {
    nameApplication: string;
    SetProfile: boolean;
    UserSelected: any;
    constructor(private _authService: AuthService) {
        this.i_views = [];
    }
    private debug: boolean;
    private i_views: ViewControllerMember[];
    public Events: EventBusService = new EventBusService(); // emits 'viewAdded'
    public rootApplicationUpdate = new Subject();
    public rootUpdateFinish = new Subject<Configuration>();

    public updateRootForReplace = new Subject<boolean>();
    public showbackdrop = new Subject<boolean>();
    get Views(): ViewControllerMember[] {
        return this.i_views;
    }


    private showViews() {
        console.log(this.i_views.map((o, i) => `${i}-${o.Active ? 1 : 0}${o.Disabled ? 1 : 0} - ${o.Type}${o.Type == 'usecases' ? '' : ` ${o.Loading ? 'loading...' : `"${o.Model.Name}"`}`}`).join(' > '));
    }
    private truncateViewFrom(i: number) {
        this.i_views = this.i_views.slice(0, i);
        this.updateActive();
    }
    private updateActive() {
        this.i_views[this.i_views.length - 1].Active = true;
    }
    private add(
        Type: string,
        Model: any,
        Active: boolean = false,
        Disabled: boolean = false,
        Loading: boolean = false,
        Parent?: AbstractConfiguration,
        Data?: any,
        ParentComponent?: any
    ): ViewControllerMember {
        if (Active) { // set active just the new one
            this.i_views.forEach(v => v.Active = false);
        }
        let newMember = new ViewControllerMember(
            { Type: Type || 'undefined', Model, Active, Disabled, Loading, Parent, Data, ParentComponent },
            this.closeByModel.bind(this),
            this, // controller
            this.debug,
            this._authService,
            () => { this.showViews(); } // updateVerbose
        );
        this.i_views.push(newMember);
        if (this.debug) this.showViews();
        return newMember;
    }



    // NOTES:

    // #region public methods
    clearBy(config: AbstractConfiguration) {
        const i = this.i_views.findIndex(o => o.Model.OID === config.OID && o.Model.Name === config.Name);
        if (i > 0)
            this.tryCloseBy(i + 1);

        return this.i_views.length == i + 1;
    }
    private tryCloseBy(i: number) {
        const list = this.i_views.slice(i, this.i_views.length).reverse();
        for (let vi in list) {
            if (!list[vi].close()) {
                this.updateActive();
                return;
            }
        }
        this.updateActive();
    }
    private tryCloseByBase() {
        this.tryCloseBy(1);

        return this.i_views.length === 1;
    }
    dispose() {
        this.i_views = [];
    }
    getParent(rule: AbstractConfiguration): AbstractConfiguration {
        const i = this.i_views.findIndex(o => o.Model === rule);
        if (i > 0)
            return this.i_views[i].Parent;
        return undefined;
    }

    getParentComponent(rule: AbstractConfiguration): ViewControllerMember {
        const i = this.i_views.findIndex(o => o.Model === rule);
        if (i > 0)
            return this.i_views[i];
        return undefined;
    }
    closeByModel(rule: AbstractConfiguration) {
        const i = this.i_views.findIndex(o => o.Model === rule);
        if (i > 0)
            this.truncateViewFrom(i);
        //this.i_views = this.i_views.slice(0, i);
    }

    closeByIndex(index) {
        this.truncateViewFrom(index);
        //this.i_views = this.i_views.slice(0, i);
    }
    getViews() {
        return this.i_views;
    }
    getView(rule: AbstractConfiguration): ViewControllerMember {
        const i = this.i_views.findIndex(o => o.Model === rule);
        if (i > 0)
            return this.i_views[i];
        return undefined;
    }
    showApplications() {
        this.i_views = [];
        return this.add('application-browser', new Rule(), true, false, true);
    }
    showPersons() {
        this.i_views = [];
        return this.add('person-browser', new Rule(), true, false, true);
    }

    showProfiles(person: Person) {
        this.tryCloseByBase()
        return this.add('profile-browser', person, true, false, true);
    }
    getUseCasesView(): ViewControllerMember {
        return this.i_views[0];
    }
    showNewApplicationForm(_newApplication: boolean = false, subscriber: Subscriber<any> = null, parentComponent: any): ViewControllerMember {

        switch (parentComponent.constructor.name) {
            case 'ProfileBrowserComponent':

                return this.addNewApplicationView(new Configuration(), { asProfile: true, newProfile: _newApplication, subscriber, }, parentComponent, false) 

            case 'ApplicationBrowserComponent':
                if (this.tryCloseByBase()) {
                    return this.addNewApplicationView(new Configuration(), { asApplication: true, newApplication: _newApplication, subscriber, }, parentComponent) 
                }

            default:
                if (this.tryCloseByBase()) {
                    return this.addNewApplicationView(new Configuration(), { asApplication: true, newApplication: _newApplication, subscriber, }, parentComponent) 
                }
        }

    }

    addNewApplicationView(config: AbstractConfiguration, data: any, parentComponent: any, closeParent = true): ViewControllerMember {
        if (closeParent) { this.i_views = this.i_views.slice(0, 1); }
        return this.add(this.getConfigView(config, "configuration-editor"), config, true, false, true, null, data, parentComponent);
    }


    showNewPersonForm(_newApplication: boolean = false, subscriber: Subscriber<any> = null, parentComponent: any): ViewControllerMember {
        if (this.tryCloseByBase())
            return this.addNewPersonView(new Configuration(), { asApplication: true, newApplication: _newApplication, subscriber, }, parentComponent) 
    }

    addNewPersonView(config: AbstractConfiguration, data: any, parentComponent: any): ViewControllerMember {
        this.i_views = this.i_views.slice(0, 1);
        return this.add(this.getConfigView(config, "configuration-editor"), config, true, false, true, null, data, parentComponent); 
    }

    showConfiguration(config: AbstractConfiguration, parent: AbstractConfiguration, subscriber?: Subscriber<any>, parentComponent?: any) {
        let viewRef;
        if (!parent || this.clearBy(parent))
            viewRef = this.add(this.getConfigView(config, 'configuration-editor'), config, true, false, false, parent, { subscriber }, parentComponent);

        if (viewRef) {
            this.moveViewToEnd();
            return viewRef;
        }
    }

    showStats(config: AbstractConfiguration, parent: AbstractConfiguration, subscriber?: Subscriber<any>, parentComponent?: any, validationView = false) {
        let viewRef;
        if (!parent || this.clearBy(parent) || validationView) {
            let indexStat = this.i_views.findIndex((x) => x.Type == "distribution-stats");
            if (indexStat > -1) {
                this.closeByIndex(indexStat)
            }
            viewRef = this.add('distribution-stats', config, true, false, false, parent, { subscriber }, parentComponent);
            if (viewRef) {
                this.moveViewToEnd();
                return viewRef;
            }
        }

    }
    showSpecs(config: Array<Setting>, parent: AbstractConfiguration, subscriber?: Subscriber<any>, parentComponent?: any) {
        let viewRef;
        if (!parent || this.clearBy(parent))
            viewRef = this.add('distribution-specs', config, true, false, false, parent, { subscriber }, parentComponent);
        if (viewRef) {
            this.moveViewToEnd();
            return viewRef;
        }
    }





    showSetting(rule: AbstractConfiguration, parent: AbstractConfiguration, subscriber?: Subscriber<any>, parentComponent?: any) {
        let viewRef;
        if (!parent || this.clearBy(parent))
            viewRef = this.add(this.getConfigView(rule, 'setting-editor'), rule, true, false, false, parent, { subscriber }, parentComponent);

        if (viewRef) {
            this.moveViewToEnd();
            return viewRef;
        }
    }

    getApplicationView(rule: AbstractRule, view: string): string {
        let ownerId = this._authService.getPOV();
        if (rule.NotReplaceable === true && rule.OwnerID != ownerId)
            return "configuration-not-replaceable"
        else
            return view;
    }



    showRuleset(rule: AbstractConfiguration, parent?: AbstractConfiguration, subscriber: Subscriber<any> = null) {
        let viewRef;
        if (!parent || this.clearBy(parent))
            viewRef = this.add(this.getConfigView(rule, "ruleset"), rule, true, false, false, parent, { subscriber });

        if (viewRef) {
            this.moveViewToEnd();
            return viewRef;
        }
    }


    getConfigView(config: AbstractConfiguration, view: string): string {
        let ownerId = this._authService.getPOV();
        if (config.NotReplaceable === true && config.OwnerID != ownerId)
            return "configuration-not-replaceable"
        else
            return view;
    }

    showVersions(date: Date, versionDates: any[],currentView:ViewControllerMember, config: AbstractConfiguration = null, subscriber?: Subscriber<any>): ViewControllerMember {
        let view;
        let indexStat = this.i_views.findIndex((x) => x == currentView);
        if (indexStat > -1) {
            this.closeByIndex(indexStat+1)
        }
        view = this.add('config-versions', new AbstractConfiguration(), true, false, true, null, { Date: date, VersionsDates: versionDates, subscriber });
        if (view) {
            this.moveViewToEnd();
            return view;
        }
    }
    replaceModel(currentModel: AbstractConfiguration, newModel: AbstractConfiguration) {
        this.getView(currentModel).Model = newModel;
    }

    editConfigurationWithParent(dataIn: any, versionDateData: any = null) {
        let viewRef;

        viewRef = this.showByType(dataIn.model, dataIn.parent, dataIn.subscriber, dataIn.parentComponent);

        if (!_.isNil(versionDateData)) {
            let data = {
                versionDateData: { date: dataIn.startDate, showTime: versionDateData.showTime, altClass: versionDateData.altClass },
                subscriber: dataIn.subscriber
            };
            viewRef.replaceData(Object.assign({}, viewRef.Data, data));
        }


        if (!viewRef) return; 

        return viewRef;
    }


    private showByType(rule: AbstractConfiguration, parent?: AbstractConfiguration, subscriber: Subscriber<any> = null, parentComponent: any = null) {
        let viewRef;
        if (CustomValidator.is(rule, 'configuration')) {
            viewRef = this.showConfiguration(rule, parent, subscriber, parentComponent);

        } else if (CustomValidator.is(rule, 'setting')) {
            viewRef = this.showSetting(rule, parent, subscriber, parentComponent);
        }
        else {
            viewRef = this.showSetting(rule, parent, subscriber, parentComponent);
        }
        this.moveViewToEnd();
        return viewRef;
    }
    moveViewToEnd() {
        this.Events.emit(new EventData('viewAdded'));
    }
    set selectedUseCaseID(useCaseID: string) {
        this.i_selectedUseCaseID = useCaseID;
    }
    get selectedUseCaseID(): string {
        return this.i_selectedUseCaseID;
    }

    set selectedUserID(UserID: string) {
        this.i_selectedUserID = UserID;
    }
    get selectedUserID(): string {
        return this.i_selectedUserID;
    }

    private i_selectedUseCaseID: string;
    private i_selectedUserID: string;

    // //#endregion
}

@Injectable({
    providedIn: 'root'
})
export class ViewControllerMember {

    constructor(config: Partial<ViewControllerMember>,
        private _closeByModel: (rule: AbstractConfiguration) => void,
        private controller: ViewControllerService,
        private debug: boolean = false,
        private _authService: AuthService,
        private updateVerbose?: any
    ) {
        Object.assign(this, config);
    }
    Type: string; 
    Model: AbstractConfiguration;
    Active: boolean;
    Disabled: boolean;
    Loading: boolean = false;
    Parent: AbstractConfiguration;
    ParentComponent: any
    Data: any;
    Events: {
        onClose$: () => void,
        onClosed$: SimpleEvent
    } = { onClose$: undefined, onClosed$: new SimpleEvent() };

    // #region public methods
    updateApplicationModel(app: AbstractConfiguration) {
        this.Model = app;
        if (this.debug) {
            this.updateVerbose();
        }
    }

    IsConfigComponentParent() {
        return !_.isNil(this.ParentComponent.ComponentName) && this.ParentComponent.ComponentName == "ConfigurationEditorComponent"
    }


    updateConfigurationList() {
        if (this.IsConfigComponentParent()) {
            this.ParentComponent.updateConfiguration()
        }
        else {
            this.controller.updateRootForReplace.next()
        }
    }


    updateApplicationList() {
        this.ParentComponent.getUseCases()
    }

    updateChildren(configRoot) {
        if (!_.isNil(configRoot.PrimChildren)) {
            configRoot.PrimChildren.forEach(element => {
                let view = this.controller.Views.find(view => view.Model.Name == element.Name)
                if (!_.isNil(view)) { view.updateModel(element) }
                this.updateChildren(element)
            });
        }

    }

    updateModel(config: AbstractConfiguration, replaceChildren = false) {
        let oldname = this.Model.Name; // for debug
        this.Model = config;
        if (replaceChildren) {
            let configRoot = config as any
            this.updateChildren(configRoot);
        }
        if (this.debug) {
            console.log(`updating model: "${oldname}" > "${config.Name}"`);
            this.updateVerbose();
        }
    }
    close(force: boolean = false): boolean {
        if (force || typeof this.Events.onClose$ === 'undefined' || this.Events.onClose$()) {
            this._closeByModel(this.Model);
            this.Events.onClosed$.resolve();
            if (this.hasObservable) this.observable.resolve();
            return true;
        }
        return false;
    }
    showVersionDate() {
        this.Data.versionDateData = {
            date: this.Model.Version//, 

        };
    }
    asUseCase() {
        return this.Data && this.Data.asUseCase;
    }
    asApplication(): boolean {
        return this.Data && this.Data.asApplication;
    }
    asProfile(): boolean {
        return this.Data && this.Data.asProfile;
    }
    newApplication() {
        return this.Data && this.Data.newApplication;
    }
    newProfile() {
        return this.Data && this.Data.newProfile;
    }



    showAdvancedView(text: string, subscriber: Subscriber<any>) {
        //   this.controller.showRuleAdvancedView(this.Model as Application, text, subscriber);
    }

    showViewAsAdvanced() {
        return this.Type == 'debugger-advanced'
            || this.Type == 'rule-advanced-view';
    }
    replaceData(data: any) {
        return this.Data = data;
    }
    updateData(data: any) {
        return this.Data = Object.assign({}, this.Data, data);
    }

    get hasObservable(): boolean {
        return typeof this.Data !== 'undefined'             
            && typeof this.Data.subscriber !== 'undefined' 
            && this.Data.subscriber !== null;               
    }

    get observable() {
        const { subscriber } = this.Data;

        if (!subscriber)
            throw 'there is not subscriber defined';

        return {
            update(data: any = null) {
                if (this.debug) console.log('updating');
                subscriber.next(data);
            },
            resolve(data: any = null) {
                if (this.debug) console.log('resolving');
                if (data !== null) subscriber.next(data); 
                subscriber.complete();
            },
            error(data: any) {
                if (this.debug) console.log('throwing error');
                subscriber.error(data || 'something went wrong');
                subscriber.complete();
            }
        }
    }

    changeType(newType: string) {
        this.Type = newType;
    }
    // #endregion
}

