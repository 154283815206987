import { Injectable } from "@angular/core";
import { SettingsService } from "@smartobjx/smart.connectors";
import { Observable } from "rxjs";
import { AuthService } from "../authentication/auth.service";

export function factory() {
  return (_server: SettingsService, _auth: AuthService): Mediator => {
    return new Mediator(_server, _auth);
  };
}

@Injectable({
  providedIn: "root",
})
export default class Mediator {
  constructor(private server: SettingsService, private auth: AuthService) {}

  FindConfigVersions(ruleID: string): Observable<any> {
    this.server.configuration.POVToken = this.auth.getPOV();
    this.server.configuration.SubscriberToken = this.auth.getTokenId();
    return this.server.findConfigVersions(
      ruleID,
      this.auth.GetSelectedPerspective()
    );
  }
}
