<mat-card appearance="outlined" class="center">
  <mat-progress-bar mode="indeterminate" class="card-top"
    [class.hidden]="!isLoading && !isLoadingTokens"></mat-progress-bar>
    <mat-label>Demo Page</mat-label><br><br>

  <mat-card-content>
   
    <mat-form-field style="display:block;">
      <mat-label>Tokens</mat-label>
      <mat-select [(ngModel)]="selectedSubscription" required [disabled]="isLoadingTokens"
        (selectionChange)="tokenSelected()">
        <ng-container *ngFor="let item of demoSubscriptions">
          <mat-option [value]="item">
            {{ item.Name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

  
      <!-- <input matInput [(ngModel)]="token" required /> -->

    <mat-form-field style="display:block;" *ngIf="showPerspectives">
      <mat-label>Perspectives</mat-label>
      <mat-select [(ngModel)]="selectedPerspective" required [disabled]="isLoadingTokens"
        (selectionChange)="perspectiveSelected(item)">
        <ng-container *ngFor="let item of perspectives">
          <mat-option [value]="item">
            {{ item.Name }}
          </mat-option>
        </ng-container>
      </mat-select>

    </mat-form-field>
    <mat-form-field style="display:block;" *ngIf="showOwner">
      <mat-label>Owner</mat-label>
      <mat-select [(ngModel)]="pov" required>
        <ng-container *ngFor="let item of dataOwners">
          <mat-option [value]="item">
            {{ item.Name }}
          </mat-option>
        </ng-container>

      </mat-select>
      <!-- <mat-hint>Choose your type of client</mat-hint> -->
    </mat-form-field>
    <!-- <a color="primary" class="link" (click)="setDefaultToken($event)">Click for default!</a> -->
    <button mat-raised-button color="primary" (click)="login($event)" style="display:block;"
      [disabled]="isLoading || !token || !pov || !selectedPerspective ">Login</button>
    <mat-checkbox [(ngModel)]="keep" style="display:block;">Keep me logged in</mat-checkbox>
    <mat-error *ngIf="errors">{{ errors }}</mat-error>
  </mat-card-content>
</mat-card>