<div mat-dialog-title [ngClass]="titleClass">
  <mat-icon *ngIf="matIcon">{{matIcon}}</mat-icon>
  <span>{{title}}</span>
</div>
<mat-dialog-content>
  <span [innerHTML]="content">
  </span>
</mat-dialog-content>
<mat-dialog-actions fxFlexAlign="end" align="end">
  <button *ngIf="!hideButton1" mat-button [mat-dialog-close]="true" [color]="button1Color">{{ button1Text }}</button>
  <button *ngIf="!hideButton2" mat-button [mat-dialog-close]="null" [color]="button2Color" #btnRef="matButton">{{ button2Text }}</button>
</mat-dialog-actions>