import {  Component,  Input,  OnInit,  OnDestroy,  ViewChild,  EventEmitter,} from "@angular/core";
import {  UntypedFormBuilder,  UntypedFormGroup,  UntypedFormControl,} from "@angular/forms";
import Mediator from "../core-services/mediator/settings.mediator";
import {  Configuration,  Setting,  SecureSetting,} from "@smartobjx/smart.objx.models";
import {  ViewControllerService,  ViewControllerMember,} from "../core-services/view/ViewControllerService";
import { AuthService } from "../core-services/authentication/auth.service";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ColumnMode } from "@swimlane/ngx-datatable";

@Component({
  selector: "distribution-stats",
  templateUrl: "./distribution-stats.component.html",
  styleUrls: ["./distribution-stats.component.scss"],
})
export class DistributionStatsComponent implements OnInit, OnDestroy {
  configurations: Configuration;
  updateField: Subject<any>;
  keyEvents: EventEmitter<string> = new EventEmitter();

  chart: { name: string; value: number }[];
  chartData;
  loading = true;
  gridData: any[];
  ColumnMode = ColumnMode;
  selectedPerspective: any;

  constructor(
    private mediator: Mediator,
    public _dialog: MatDialog,
    private viewController: ViewControllerService,
    private fb: UntypedFormBuilder,
    private _authService: AuthService,
    private _info: MatSnackBar
  ) {
    this.minDate = new Date();
    this.advOptEnabled = false;
    this.pov = this._authService.getPOV();
    this.selectedPerspective = this._authService.GetSelectedPerspective();
  } // #region Properties

  get model(): Setting | SecureSetting {
    return this.i_Model;
  }

  availableTypes = ["String", "int", "boolean", "long"];

  @Input()
  set model(newModel: Setting | SecureSetting) {
    this.i_Model = newModel;
    this.selectedIndex = -1;
  }

  get selectedIndex(): number {
    return this.i_SelectedIndex;
  }

  set selectedIndex(clickedItemIndex: number) {
    this.i_SelectedIndex = clickedItemIndex;
  }

  get descriptionHint(): string {
    return "This text should be a description of the setting or group of <b>settings</b>. This text should help the administrators to understand what the value affect.";
  }
  get formIsDisabled(): boolean {
    return (
      !this.form ||
      (this.form.pristine && this.disableOrEnableStack.length === 0) ||
      !this.form.valid
    );
  }
  get priorToParent(): boolean {
    const now = new Date();
    return now < this.minDate;
  }

  get startDate(): Date {
    const { Data } = this.view;
    return Data ? Data.startDate : undefined;
  }
  minDate: Date;
  initialDate: Date; 
  advOptEnabled: boolean;

  get isNew(): boolean {
    return !!this.model && !this.model.OID;
  }
  private get isUntouched(): boolean {
    return this.form.pristine && this.disableOrEnableStack.length == 0;
  }

  private i_startDate: Date = new Date();
  startDateForceInvalid: boolean = false;
  // #endregion

  // #region Data Elements
  private i_Model: Setting | SecureSetting;
  private i_SelectedIndex: number;
  private form: UntypedFormGroup;
  private disableOrEnableStack: any[] = [];
  private pov: string;

  @Input() view: ViewControllerMember;
  get isLoading(): boolean {
    return this.view.Loading;
  }
  get isActive(): boolean {
    return this.view.Active;
  }
  get asUseCase(): boolean {
    return this.view.asUseCase();
  }
  get versionDateData(): boolean {
    return this.view.Data && this.view.Data.versionDateData;
  }

  @ViewChild("nameInput", { static: false }) nameInputRef: any;
  private disableFocused: boolean = false;
  fixDateValue: string;
  @ViewChild("startDateMinVerification", { static: false })
  startDateMinVerificationDialog: any;
  // #endregion

  // #region Event Emitters
  // #endregion
  // #region Event Handlers

  // #region public

  ShowDetail($event) {
    if (
      !_.isNil($event) &&
      $event.type == "click" &&
      $event.column.prop == "Value"
    ) {
      this.mediator.newStatsSpecs(
        this.view,
        $event.row,
        this.model,
        this,
        this.viewController
      );
    }

    console.log($event);
  }

  checkDateValues(date: Date) {
    return date >= this.minDate;
  }

  toggleAdvancedOptions() {
    this.advOptEnabled = !this.advOptEnabled;
  }

  private getType(record: any) {
    let type = (record as any).$type;
    if (!type) return "undefined";
    if (type.includes("Rules.RuleSet,")) return "ruleset";
    if (type.includes("Rules.Rule,")) return "rule";
  }

  private getControl(name: string): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  // #endregion
  // #endregion

  // #region Construction & Finalization

  ngOnDestroy() {}

  ngOnInit() {
    this.loading = true;
    this.updateField = new Subject();
    let model = this.model as any;
    let setting = this.view.Parent as Setting;
    this.mediator
      .GetDistributionList(this.selectedPerspective, setting.Name)
      .subscribe((distribution) => {
        this.chartData = distribution.map(function (element) {
          return { name: element.Value, value: element.Percent };
        });
        this.gridData = distribution;
        this.loading = false;
        console.log(distribution);
      });

  }
  ngOnChanges(changes: any): void {}

  single: any[];
  views: any[] = [540, 400];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = "Value";
  showYAxisLabel: boolean = true;
  xAxisLabel: string = "Percent";

  colorScheme = {
    domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"],
  };

  onSelectedRows(data): void {
    console.log(data);
    if (!_.isEmpty(data)) {
      this.mediator.newStatsSpecs(
        this.view,
        data,
        this.model,
        this,
        this.viewController
      );
    }
  }

  onActivate(data): void {
    console.log("Activate", JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log("Deactivate", JSON.parse(JSON.stringify(data)));
  }
}
