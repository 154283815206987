import { Component, OnInit } from '@angular/core';
import { Organization, Subscription } from '@smartobjx/smart.objx.models';
import { AuthService } from '../core-services/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Mediator from '../core-services/mediator/login-portal.mediator';
import { mergeMap } from 'rxjs/operators';
import { ViewControllerService } from '../core-services/view/ViewControllerService';

@Component({
  selector: 'app-login-profile',
  templateUrl: './login-profile.component.html',
  styleUrls: ['./login-profile.component.scss']
})
export class LoginProfileComponent implements OnInit {
  errors
  pov: any = {};
  private _token: string;
  keep: boolean;
  selectedSubscription: any;
  dataOwners: Array<Organization>;
  demoSubscriptions: Subscription[];
  token: string;

  constructor(
    private authService: AuthService,
    private mediator: Mediator,
    private viewController: ViewControllerService,
    private route: ActivatedRoute,
    private _router: Router) { }

  ngOnInit() {
    let sub
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        if (!_.isNil(params) && !_.isNil(params.UserId)) {
          this.viewController.selectedUserID = params.UserId
          this.mediator.GetTokensforSettings("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", "BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849")
            .pipe(mergeMap((subs: any) => {
              sub = subs[0];
              return this.mediator.GetNestedClients("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", sub.AuthTokens[0].OID, sub.OID)
            }))
            .subscribe((orgs: Array<Organization>) => {
              this.dataOwners = orgs
              this.pov = this.dataOwners.find(findSub => findSub.OID == sub.OID)
              this.isLoadingTokens = false
              this.token = sub.AuthTokens[0].OID
              this.pov.OID = sub.OID
              this.login("")
            })
        }


      })
    this.viewController.SetProfile = true
    console.log("test Deploy 16112022")
    this.isLoadingTokens = true
    this.mediator.GetTokensforSettings("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", "BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849")
      .subscribe((subs: any) => {
        this.demoSubscriptions = subs;
        this.isLoadingTokens = false
      })

  }




  setDefaultToken(event) {
    // this.token = 'fd8efd80-f215-4c73-95f2-23841e98acbd';
    this.token = '5f87bf0e89a8427aaa5a8f4c01ffd777';
  }
  tokenSelected() {
    this.isLoadingTokens = true

    this.token = this.selectedSubscription.AuthTokens[0].OID
    this.pov.OID = this.selectedSubscription.OID
    this.mediator.GetNestedClients("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", this.selectedSubscription.AuthTokens[0].OID, this.selectedSubscription.OID)
      .subscribe((orgs: Array<Organization>) => {
        this.dataOwners = orgs
        this.pov = this.dataOwners.find(findSub => findSub.OID == this.selectedSubscription.OID)
        this.isLoadingTokens = false

      }
        , err => {


          if (err.status == 404) {

          }
        })

  }
  login(event) {
    if (this.token != null && this.pov != null) {
      this.hasErrors = false;
      this.authService.setTokenId(this.token);
      this.authService.setPOV(this.pov.OID.toString());

      this.authService.userName = null;
      this.isLoading = true;
      this.mediator.checkLoginAndGetName(this.pov.OID.toString(), this.token,null)
        .subscribe({
          next: (sub: any) => {
            this.authService.userName = this.pov.Name;
            this.authService.CanAdd = sub.canAdd
          },
          error: e => {
            console.error(e);
            this.isLoading = false;
            this.hasErrors = true;
          },
          complete: () => {
            this.isLoading = false;

            if (this.keep) {
              localStorage.setItem('authToken', this.token);
              localStorage.setItem('authPov', this.pov.OID.toString());
            }
            this.viewController.SetProfile = true

            this._router.navigate(['asd']);
          }
        });
    }
  }

  organizations: any[] = [];
  _test_organizations: any[] = [
    { id: '45ef2936-160e-47dc-bd17-3dc0060acec9', name: 'SaaS publisher' },
    { id: '25ef2936-160e-47dc-bd17-3dc0060acec9', name: 'Client' },
    { id: '05ef2936-160e-47dc-bd17-3dc0060acec9', name: 'Customer' }
  ]
  isLoading: boolean = false;
  isLoadingTokens: boolean = true;
  disablePOVs: boolean = true;
  hasErrors: boolean = false;
  errorMessage: string = 'Error: check the data and try again.';
  tokens: any = [];
}