import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { AppComponent } from "./app.component";
//#region material
import { MAT_DIALOG_DATA } from "@angular/material/dialog"
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
//#endregion
import { SettingSetAdmModule } from "./setting-set-adm/setting-set-adm.module";
import { AuthGuardService } from "./core-services/authentication/guards/auth-guard.service";
import { LoginPortalComponent } from "./login-portal/login-portal.component";
import { LogoutComponent } from "./login-portal/logout.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SettingAdmComponent } from "./setting-set-adm/setting-set-adm.component";
import { LoginProfileComponent } from "./login-profile/login-profile.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptorService } from "./core-services/interceptor.service";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RegisterSubscriptionComponent } from "./register-subscription/register-subscription.component";

const appRoutes: Routes = [
  {
    path: "",
    component: SettingAdmComponent,
    pathMatch: "full",
    canActivate: [AuthGuardService],
  },
  {
    path: "login",
    component: LoginPortalComponent,
    pathMatch: "full",
  },
  {
    path: "loginprofile",
    component: LoginProfileComponent,
    pathMatch: "full",
  },
  {
    path: "registerSubscription",
    component: RegisterSubscriptionComponent,
    pathMatch: "full",
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginPortalComponent,
    LoginProfileComponent,
    LogoutComponent,
    RegisterSubscriptionComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatToolbarModule,
    MatDividerModule,
    MatMenuModule,
    MatProgressBarModule,
    MatCheckboxModule,
    SettingSetAdmModule,
    RouterModule.forRoot(appRoutes, {}),
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
