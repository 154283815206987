import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import EventData from 'src/app/shared/EventData.class';

export default class EventBusService {
  private subject$ = new Subject();

  constructor() {}

  emit(event: EventData){
      this.subject$.next(event);
  }

  on(eventName: string, action: any ): Subscription {
      return this.subject$.pipe(
          filter( (e: EventData) => e.name == eventName ),
          map( (e: EventData) => e["value"])
      ).subscribe( action );
  }
}