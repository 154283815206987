import { Injectable } from "@angular/core";
import { RulesService } from "@smartobjx/smart.connectors";
import { AuthService } from "../authentication/auth.service";

export function factory() {
  return (_server: RulesService, _auth: AuthService): Mediator => {
    return new Mediator(_server, _auth);
  };
}

@Injectable({
  providedIn: "root",
})
export default class Mediator {
  constructor(_server: any, private auth: AuthService) {
    this.server = _server;
  }
  private server: RulesService;

  fixUpdateToken(): void {
    this.server.configuration.POVToken = this.auth.getPOV();
  }
}
