import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import {  AbstractConfiguration,  AbstractRule,  Configuration,} from "@smartobjx/smart.objx.models";
import {  ViewControllerService,  ViewControllerMember,} from "../core-services/view/ViewControllerService";
import { Tools } from "../shared/Tools";
import { AuthService } from "../core-services/authentication/auth.service";
import { SimpleDialogComponent } from "../simple-dialog/simple-dialog.component";
import { CustomValidator } from "../shared/validation";
import Mediator from "../core-services/mediator/settings.mediator";

@Component({
  selector: "configuration-not-replaceable",
  templateUrl: "./configuration-not-replaceable.component.html",
  styleUrls: ["./configuration-not-replaceable.component.scss"],
})
export class ConfigurationNotReplaceableComponent implements OnInit {
  private i_startDate: Date = new Date();

  appendAbstract(abstractModel) {
    let type = abstractModel.$type as string;
    if (type.includes("Settings.Configuration")) {
      this.appendConfig(abstractModel);
    } else if (type.includes("Settings.Setting")) {
      this.appendSetting(abstractModel);
    } else {
      throw "undefined type for save";
    }
  }

  appendSetting(settingModel = null) {
    this.checkStartDateBefore((startDate) => {
      this.selectedIndex = -1;
      if (settingModel != null && settingModel != undefined) {
        this.mediator.editConfiguration(
          settingModel.OID,
          this.view,
          this.model,
          startDate,
          this,
          this.versionDateData,
          this.viewController
        );
      } else {
        this.mediator.newSetting(
          this.view,
          this.model,
          startDate,
          this,
          this.viewController
        );
      }
    });
  }

  appendConfig(configurationModel: Configuration = null) {
    this.checkStartDateBefore((startDate) => {
      this.selectedIndex = -1;
      if (configurationModel != null && configurationModel != undefined) {
        this.mediator.editConfiguration(
          configurationModel.OID,
          this.view,
          this.model,
          startDate,
          this,
          this.versionDateData,
          this.viewController
        );
      } else {
        this.mediator.newConfiguration(
          this.view,
          this.model,
          startDate,
          this,
          this.viewController
        );
      }
    });
  }
  checkStartDateBefore(fn: (startDate: Date) => void) {
    const startDate = this.i_startDate;
    if (!startDate) {
      this.fireStartDateError();
      return;
    }

    const now = new Date();
    const version = startDate > now ? startDate : now;

    fn(version);
  }

  fireStartDateError() {
    this._info.open("Start Date must be valid before this action", "", {
      duration: 3000,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: "info-warn",
    });
  }

  onDisableOrEnable(rule: AbstractRule) {
    this.updateSelected(rule);
    let disable = !rule.Disabled;
    this._dialog
      .open(SimpleDialogComponent, {
        panelClass: "smart-objx",
        autoFocus: false,
        data: {
          title: "Attention",
          titleClass: "warning",
          matIcon: "warning_amber",
          button1Text: `Yes, ${disable ? "disable" : "enable"} it`,
          content:
            `Do you really want to ${disable ? "disable" : "enable"} the ` +
            this.getType(rule) +
            ` "<b>${rule.Name}</b>" ?`,
        },
      })
      .afterClosed()
      .toPromise()
      .then((action) => {
        (document.activeElement as any).blur(); // fix force blur on x
        this.selectedIndex = -1;
        if (action) {
          this.viewController.clearBy(this.model);
          this.stackToDisableOrEnable({ rule: rule, disable: disable });
        }
      });
  }

  private updateSelected(rule: AbstractRule) {
    this.selectedIndex = (this.model as any).PrimChildren.indexOf(rule);
  }

  private stackToDisableOrEnable(data: any) {
    let filter = this.disableOrEnableStack.filter((o) => o.rule === data.rule);
    if (filter.length) {
      const i = this.disableOrEnableStack.indexOf(filter[0]);
      this.disableOrEnableStack.splice(i, 1);
    } else {
      this.disableOrEnableStack.push(data);
    }
  }

  private getType(record: any) {
    let type = (record as any).$type;
    if (!type) return "undefined";
    if (type.includes("Settings.Configuration,")) return "configuration";
    if (type.includes("Settings.Setting,")) return "setting";
  }

  // #endregion
  // #region Construction & Finalization
  constructor(
    private viewController: ViewControllerService,
    private mediator: Mediator,
    public _dialog: MatDialog,
    private router: Router,
    private _authService: AuthService,
    private _info: MatSnackBar
  ) {
    this.pov = this._authService.getPOV();
    console.log(this.model);
  }

  ngOnInit() {}

  ngOnDestroy() {}
  // #endregion

  // #region Properties
  get model(): AbstractConfiguration {
    return this.i_Model;
  }

  @Input()
  set model(newModel: AbstractConfiguration) {
    this.i_Model = newModel;
    this.selectedIndex = -1;
  }

  get startDateAsString(): Date {
    return CustomValidator.ensureDate(this.model.Version);
  }

  get selectedIndex(): number {
    return this.i_SelectedIndex;
  }

  set selectedIndex(clickedItemIndex: number) {
    this.i_SelectedIndex = clickedItemIndex;
  }

  get viewType() {
    return Tools.getType(this.model);
  }

  // #endregion

  // #region Data Elements
  private i_Model: AbstractConfiguration;
  private i_SelectedIndex: number;
  private pov: string;
  private disableOrEnableStack: any[] = [];
  get versionDateData(): boolean {
    return this.view.Data && this.view.Data.versionDateData;
  }
  private get selectedDate() {
    return this.versionDateData
      ? Tools.dateToURLStringAsDate((this.versionDateData as any).date)
      : false;
  }
  @ViewChild("picker", { static: false }) picker: any;

  @Input() view: ViewControllerMember;
  get isLoading(): boolean {
    return this.view.Loading;
  }
  get isActive(): boolean {
    return this.view.Active;
  }

  // #endregion

  // #region Events
  // #endregion
}
