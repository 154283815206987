import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _tokenId = null;
  private _pov = null;
  userName = null;
  public httpClient: HttpClient;
  CanAdd: boolean;
  ownerName: null;
  PerspectiveName: any;

  constructor(public http: HttpClient) {
    this.httpClient = http;
  }
  getUser() {
    if (this._pov) {
      return this.userName;
    }
    return null;
  }
  setTokenId(token) {
    this._tokenId = token;
  }

  getTokenId(): any {
    return this._tokenId;
  }

  setPOV(pov: String) {
    this._pov = pov;

    var e = new CustomEvent("povchanged", { detail: pov });

    window.dispatchEvent(e);
  }

  GetSelectedPerspective() {
    return localStorage.getItem("perspectiveSelected");
  }

  getPOV(): any {
    return this._pov;
  }

  isAuthenticated() {
    if (this._tokenId != null) return true;

    // check stored session
    const token = localStorage.getItem("authToken");
    const pov = localStorage.getItem("authPov");
    if (token && pov) {
      this.setTokenId(token);
      this.setPOV(pov);
      return true;
    }

    // not logged in
    return false;
  }

  logOut() {
    this._tokenId = null;
    this._pov = null;
    localStorage.removeItem("authToken");
    localStorage.removeItem("authPov");
    localStorage.removeItem("perspectiveSelected");
  }
}
