import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { RuleSet, UseCase, Application } from "@smartobjx/smart.objx.models";
import {  ViewControllerService,  ViewControllerMember,} from "../core-services/view/ViewControllerService";
import Mediator from "../core-services/mediator/settings.mediator";
import { SimpleDialogComponent } from "../simple-dialog/simple-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { Tools } from "../shared/Tools";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "profile-browser",
  templateUrl: "./profile-browser.component.html",
  styleUrls: ["./profile-browser.component.scss"],
})
export class ProfileBrowserComponent implements OnInit, OnDestroy {
  i_NameApplication: string;
  updateRefresh: any;
  onDestroy$: Subject<boolean> = new Subject();
  constructor(
    public _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private viewController: ViewControllerService,
    private mediator: Mediator
  ) {}
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
  ngOnInit(): void {
    this.updateRefresh = this.viewController.updateRootForReplace
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getUseCases(false);
      });
  }
  //#region Event Handlers
  getUseCases(reloadCurrent = false) {
    this.useCasesError = null; 
    this.mediator
      .FindProfilesFor(this.view, this.viewController.selectedUserID)
      .subscribe(
        (applications) => {
          if (applications.length == 0) {
            this.isEmpty = true;
          }
          this.useCasesFull = applications;
          this.model = applications;
          if (reloadCurrent) {
            var app = applications.find(
              (appplication) =>
                appplication.Name == this.viewController.nameApplication
            );
            this.mediator.reloadConfiguration(
              app,
              () => (this.selectedUseCaseID = null),
              () => {
                this.selectedUseCaseID = app.ConfigId;
                this.selectedNameApplication = app.Name;
              },
              (application) => {
                if (
                  this.viewController.getConfigView(
                    application,
                    "configuration-editor"
                  ) === "configuration-not-replaceable"
                ) {
                  let view = this.viewController.getView(application);
                  view.changeType("configuration-not-replaceable");
                }
              },
              this,
              this.viewController
            );
          }
        },
        (error: HttpErrorResponse) => {
          this.useCasesError = error.message;
        }
      );
  }

  onVersions(record: any, date: Date, versionDates: any[]) {
    this.mediator.showConfigVersions(
      record,
      date,
      versionDates,
      () => (this.selectedUseCaseID = null),
      this.viewController,
      this.view
    );
    this.selectedUseCaseID = record.OID;
  }

  filterUseCases(type: string) {
    switch (type) {
      case "active":
        this.useCases = this.useCasesFull.filter((o) => !(o as any).Disabled);
        break;
      case "disabled":
        this.useCases = this.useCasesFull.filter((o) => (o as any).Disabled);
        break;
      default:
        this.useCases = this.useCasesFull;
    }
  }

  useCaseCreated(name: string) {
    this._snackBar.open(`Your Use Case ${name} was successfully added.`, "", {
      duration: 2000,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: "info-primary",
    });

    setTimeout(() => {
      this.getUseCases();
      this.isEmpty = false;
    }, 500);
  }

  //#region list events

  onCreate() {
    this.mediator.newApplication(
      this.view,
      (name, oid) => {
        this.useCaseCreated(name);
        this.selectedUseCaseID = oid;
      },
      this,
      this.viewController
    );
  }
  onEdit(application: Application) {
    this.mediator.editProfile(
      application,
      () => (this.selectedUseCaseID = null),
      () => {
        this.selectedUseCaseID = application.OID;
        this.selectedNameApplication = application.Name;
      },
      (application) => {
        if (
          this.viewController.getConfigView(
            application,
            "configuration-editor"
          ) === "configuration-not-replaceable"
        ) {
          let view = this.viewController.getView(application);
          view.changeType("configuration-not-replaceable");
        }
      },
      this,
      this.viewController
    );
  }

  onDelete(application: Application) {
    this.selectedUseCaseID = application.OID;
    this._dialog
      .open(SimpleDialogComponent, {
        panelClass: "smart-objx",
        autoFocus: false,
        data: {
          title: "Are you sure?",
          titleClass: "error",
          matIcon: "close",
          button1Text: "Yes, delete it",
          content:
            "Do you really want to want to delete the application " +
            `<b>${application.Name}</b> ?`,
        },
      })
      .afterClosed()
      .toPromise()
      .then((action) => {
        (document.activeElement as any).blur();
        this.selectedUseCaseID = null;
        if (action) {
          this.view.Loading = true;
          this.mediator
            .deleteApplicationWith(application.OID, this.view)
            .subscribe(() => {
              this._dialog
                .open(SimpleDialogComponent, {
                  panelClass: "smart-objx",
                  data: {
                    title: "Success!",
                    titleClass: "success",
                    matIcon: "check",
                    hideButton1: true,
                    closeOnSeconds: 3,
                    button2Text: "close",
                    content:
                      "Your application " +
                      `<b>${application.Name}</b>` +
                      " was succefully deleted.",
                  },
                })
                .afterClosed()
                .toPromise();
              this.getUseCases();
            });
        }
      });
  }
  //#endregion

  searchPreview(e: any, el: any) {
    if (e.key === "Enter") {
      this.search(el);
    }
    // this.updateAutocomplete( el );
  }
  search(el: any, clean: boolean = true) {
    const value = el.value.trim();
    if (value && Tools.isNotInArray(value, this.searchChips))
      this.searchChips.push(value.toLowerCase());
    if (clean) el.value = "";
  }
  removeChip(value: string) {
    const i = this.searchChips.indexOf(value);
    this.searchChips.splice(i, 1);
  }
  clearSearch() {
    this.searchChips = [];
    this.currentSearch = "";
  }

  // #region Construction & Finalization

  ngAfterViewInit() {
    setTimeout(() => {
      this.getUseCases();
    });
  }
  // #endregion

  // #region Properties
  get model(): UseCase[] {
    return this.i_Model;
  }

  @Input()
  set model(newModel: UseCase[]) {
    this.i_Model = newModel;
  }

  get selectedUseCaseID(): string {
    return this.i_SelectedUseCaseID;
  }

  set selectedUseCaseID(oid: string) {
    this.i_SelectedUseCaseID = oid;
    if (oid) this.viewController.selectedUseCaseID = oid;
  }
  set selectedNameApplication(name: string) {
    this.i_NameApplication = name;
    if (name) this.viewController.nameApplication = name;
  }

  get selectedUseCaseIndex(): number {
    return this.useCasesFiltered
      ? this.useCasesFiltered.map((o) => o.OID).indexOf(this.selectedUseCaseID)
      : -1;
  }

  get useCasesFull(): UseCase[] {
    return this.i_UseCasesFull;
  }
  set useCasesFull(newUseCases: UseCase[]) {
    this.i_UseCasesFull = newUseCases;
    this.useCases = newUseCases;
  }

  get useCases(): UseCase[] {
    return this.i_UseCases;
  }

  set useCases(newUseCases: UseCase[]) {
    this.i_UseCases = newUseCases;
  }

  get useCasesFiltered(): UseCase[] {
    return this.searchList.length
      ? this.useCasesFull.filter(
          (o) =>
            this.searchList.filter((c) =>
              Tools.isInArray(c, o.Name.toLowerCase())
            ).length
        )
      : this.useCasesFull;
  }
  get searchList(): string[] {
    const list = this.searchChips.slice();
    if (this.currentSearch) {
      list.push(this.currentSearch.toLowerCase());
    }
    return list;
  }
  private searchChips: string[] = [];
  private currentSearch: string;
  private autocompleteList: string[] = [];

  private i_Model: UseCase[] = [];
  private i_UseCases: UseCase[] = [];
  private i_SelectedUseCaseID: string;
  useCasesError: string;
  debugging: RuleSet;

  @Input() view: ViewControllerMember;
  get isLoading(): boolean {
    return this.view.Loading;
  }
  get isActive(): boolean {
    return this.view.Active;
  }
  isEmpty: boolean = false;

  showFilterMessage: string;
  private i_UseCasesFull: UseCase[] = [];
  // #endregion
}
