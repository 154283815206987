import {  AfterContentChecked,  ChangeDetectorRef,  Component,} from "@angular/core";
import {  ApplicationService,  ApplicationServiceConfiguration,  PersonService,  PersonServiceConfiguration,  ProfilesService,  ProfilesServiceConfiguration,  RulesService,
  RulesServiceConfiguration,  SettingsService,  SettingsServiceConfiguration,  StructuresService,  StructuresServiceConfiguration,} from "@smartobjx/smart.connectors";
import { AuthService } from "./core-services/authentication/auth.service";
import { ViewControllerService } from "./core-services/view/ViewControllerService";
import { combined } from "./core-services/mediator/combined";
import { rulesConfigurationService } from "./core-services/rulesConfigurationService";
import { Router } from "@angular/router";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { viewControllerService } from "./core-services/viewControllerService-factory";
import { settingsConfigurationService } from "./core-services/settingsConfigurationService";
import { structureConfigurationService } from "./core-services/structureConfigurationService";
import { profilesConfigurationService } from "./core-services/profilesConfigurationService";
import { applicationConfigurationService } from "./core-services/applicationConfigurationService";
import { personConfigurationService } from "./core-services/personConfigurationService";

export function RulesFactoryService() {
  return (
    _rulesServiceConfiguration: RulesServiceConfiguration
  ): RulesService => {
    return new RulesService(_rulesServiceConfiguration);
  };
}

export function StructuresFactoryService() {
  return (
    _structuresServiceConfiguration: StructuresServiceConfiguration
  ): StructuresService => {
    return new StructuresService(_structuresServiceConfiguration);
  };
}

export function SettingsFactoryService() {
  return (
    _settingsServiceConfiguration: SettingsServiceConfiguration
  ): SettingsService => {
    return new SettingsService(_settingsServiceConfiguration);
  };
}

export function PersonFactoryService() {
  return (
    _personServiceConfiguration: PersonServiceConfiguration
  ): PersonService => {
    return new PersonService(_personServiceConfiguration);
  };
}
export function ApplicationFactoryService() {
  return (
    _applicationServiceConfiguration: ApplicationServiceConfiguration
  ): ApplicationService => {
    return new ApplicationService(_applicationServiceConfiguration);
  };
}
export function ProfilesFactoryService() {
  return (
    _profilesServiceConfiguration: ProfilesServiceConfiguration
  ): ProfilesService => {
    return new ProfilesService(_profilesServiceConfiguration);
  };
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [
    RulesService,
    SettingsService,
    StructuresService,
    ProfilesService,
    ApplicationService,
    PersonService,
    ...combined(),
    {
      provide: ApplicationService,
      useFactory: ApplicationFactoryService(),
      deps: [ApplicationServiceConfiguration],
    },
    {
      provide: ProfilesService,
      useFactory: ProfilesFactoryService(),
      deps: [ProfilesServiceConfiguration],
    },
    {
      provide: RulesService,
      useFactory: RulesFactoryService(),
      deps: [RulesServiceConfiguration],
    },
    {
      provide: StructuresService,
      useFactory: StructuresFactoryService(),
      deps: [StructuresServiceConfiguration],
    },
    {
      provide: SettingsService,
      useFactory: SettingsFactoryService(),
      deps: [SettingsServiceConfiguration],
    },
    {
      provide: PersonService,
      useFactory: PersonFactoryService(),
      deps: [PersonServiceConfiguration],
    },
    {
      provide: ProfilesServiceConfiguration,
      useFactory: profilesConfigurationService(),
      deps: [AuthService],
    },
    {
      provide: PersonServiceConfiguration,
      useFactory: personConfigurationService(),
      deps: [AuthService],
    },
    {
      provide: ApplicationServiceConfiguration,
      useFactory: applicationConfigurationService(),
      deps: [AuthService],
    },
    {
      provide: RulesServiceConfiguration,
      useFactory: rulesConfigurationService(),
      deps: [AuthService],
    },
    {
      provide: SettingsServiceConfiguration,
      useFactory: settingsConfigurationService(),
      deps: [AuthService],
    },
    {
      provide: StructuresServiceConfiguration,
      useFactory: structureConfigurationService(),
      deps: [AuthService],
    },

    {
      provide: ViewControllerService,
      useFactory: viewControllerService(),
      deps: [AuthService],
    },
  ],
})
export class AppComponent implements AfterContentChecked {
  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private viewController: ViewControllerService,
    private domSanitizer: DomSanitizer,
    private authSvc: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    console.log("test pipeline");

    this.registerSvgIcon(
      "smart_objx_colorblack",
      "smartObjx-logo_smartObjx-colorblack-RGB"
    );
    this.registerSvgIcon(
      "smart_objx_colorwhite",
      "smartObjx-logo_smartObjx-colorwhite-RGB"
    );
    this.registerSvgIcon(
      "smart_objx_black",
      "smartObjx-logo_smartObjx-black-RGB"
    );
    this.registerSvgIcon(
      "smart_objx_white",
      "smartObjx-logo_smartObjx-white-RGB"
    );
    this.registerSvgIcon("use_cases_empty", "use-cases-empty");
    this.registerSvgIcon("dead_end", "dead-end");
    this.registerSvgIcon("upload_arrow", "upload-arrow");
  }
  private registerSvgIcon(name: string, assetName: string) {
    this.matIconRegistry.addSvgIcon(
      name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/${assetName}.svg`
      )
    );
  }
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
    if (this.viewController.SetProfile) {
      this.title = "PROFILES";
    } else {
      this.title = "SETTINGS";
    }
  }

  logout() {
    this.viewController.dispose();
    this.router.navigate(["/logout"]);
  }

  get user(): string {
    const auth = this.authSvc as AuthService;
    return auth.getUser();
  }

  get owner(): string {
    const auth = this.authSvc as AuthService;
    return auth.ownerName;
  }

  get perspective(): string {
    const auth = this.authSvc as AuthService;
    return auth.PerspectiveName;
  }
  getShowMenu(): boolean {
    return !!this.user;
  }
  title: string = "SETTINGS";
}
