// #region consts (exported)
export const ALL_LETTERS_OR_ALL_NUMBER_REGEXP = /^([a-zA-Z]+|-?[\d.]+)$/;

export const ALL_LETTERS_OR_ALL_NUMBER_OR_CONSTANT_REGEXP =
  /^('.*'|([a-zA-Z]([a-zA-Z\d]+)?|-?[\d.]+))$/;

export const DATETIME_REGEXP =
  /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}\s(0?[0-9]|[1][0-9]|2[0123])[:](0?[0-9]|[12345][0-9])$/;
// explanation: every character between single quotes | a letter followed by letters or numbers | numbers (negative too)
// #endregion

export class CustomValidator {
  static fixDate(date: any): Date {
    return new Date(date + ".000Z");
  }

  static fixDateUTC(date: any) {
    let dateUtc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    return new Date(dateUtc);
  }

  static fixTimeZero(date: Date): Date {
    date.setSeconds(0);
    return date;
  }

  static fixSecondstoEnd(date: Date): Date {
    date.setSeconds(59);
    return date;
  }
  // Validates URL
  static urlValidator(url: any): any {
    if (url.pristine) {
      return null;
    }
    const URL_REGEXP =
      /^(http?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    url.markAsTouched();
    if (URL_REGEXP.test(url.value)) {
      return null;
    }
    return {
      invalidUrl: true,
    };
  }
  // Validates passwords
  static matchPassword(group: any): any {
    const password = group.controls.password;
    const confirm = group.controls.confirm;
    if (password.pristine || confirm.pristine) {
      return null;
    }
    group.markAsTouched();
    if (password.value === confirm.value) {
      return null;
    }
    return {
      invalidPassword: true,
    };
  }
  // Validates numbers
  static numberValidator(number: any): any {
    if (number.pristine) {
      return null;
    }
    const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
    number.markAsTouched();
    if (NUMBER_REGEXP.test(number.value)) {
      return null;
    }
    return {
      invalidNumber: true,
    };
  }
  // Validates US SSN
  static ssnValidator(ssn: any): any {
    if (ssn.pristine) {
      return null;
    }
    const SSN_REGEXP =
      /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
    ssn.markAsTouched();
    if (SSN_REGEXP.test(ssn.value)) {
      return null;
    }
    return {
      invalidSsn: true,
    };
  }
  // Validates US phone numbers
  static phoneValidator(number: any): any {
    if (number.pristine) {
      return null;
    }
    const PHONE_REGEXP = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    number.markAsTouched();
    if (PHONE_REGEXP.test(number.value)) {
      return null;
    }
    return {
      invalidNumber: true,
    };
  }
  // Validates zip codes
  static zipCodeValidator(zip: any): any {
    if (zip.pristine) {
      return null;
    }
    const ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
    zip.markAsTouched();
    if (ZIP_REGEXP.test(zip.value)) {
      return null;
    }
    return {
      invalidZip: true,
    };
  }
  static allLettersOrAllNumbersOrConstant(text: any): any {
    if (text.pristine) {
      return null;
    }
    text.markAsTouched();
    if (ALL_LETTERS_OR_ALL_NUMBER_OR_CONSTANT_REGEXP.test(text.value)) {
      return null;
    }
    return {
      invalidAllLettersOrAllNumber: true,
    };
  }

  static parseDate(value: any): Date | null {
    if (DATETIME_REGEXP.test(value)) {
      let [date, time] = value.split(" ");
      let [month, day, year] = date.split("/");
      let [hour, minute] = time.split(":");
      let valueAsDate = new Date(year, parseInt(month) - 1, day, hour, minute);
      return valueAsDate;
    }
    return null;
  }

  static getType(record: any): string {
    if (record.hasOwnProperty("RuleID")) return "usecase";
    let type = (record as any).$type;
    if (!type) return "undefined";

    if (type.includes("Settings.Application,")) return "application";
    if (type.includes("Settings.Configuration,")) return "configuration";
    if (type.includes("Settings.Setting,")) return "setting";
    if (type.includes("Rules.Rule,")) return "rule";
    if (type.includes("Rules.CalculationRule,")) return "calculationrule";
    if (type.includes("Rules.CallbackRule,")) return "callbackrule";
  }

  static is(record: any, type: string): boolean {
    return this.getType(record) === type;
  }
  static ensureDate(date: any): Date {
    if (typeof date === "string" && !!date) {
      return new Date(date + ".000Z");
    }
    return date;
  }
}
