<!-- <div>{{ simpleDate(model) }} | arrow pressed: {{ arrowPressed }}</div> -->
 <div class="custom-date-label">{{placeholder}}</div>
<div class="custom-date" #container (keydown)="keyPressed( $event )" (keyup)="keyReleased( $event )">
    <mat-form-field style="width: 55px; z-index: 1;">

        <input matInput type="number" #month
            [placeholder]="placeholder" style="width: 34px; padding-left: 14px;"
            [(ngModel)]="dateMonth"
            (focus)="setFocused()" (blur)="removeFocused()"
        />
        <div class="divider">/</div>
    </mat-form-field>
    <mat-form-field style="margin-top: 0; width: 55px;margin-left: -1px;">
        <input matInput type="number" #day style="width: 34px;padding-left: 4px;"
            [(ngModel)]="dateDay"
            (focus)="setFocused()" (blur)="removeFocused()"
        />
        <div class="divider">/</div>
    </mat-form-field>
    <mat-form-field style="margin-top: 0; width: 64px;margin-left: -1px;">
        <input matInput type="number" #year style="width: 55px;"
            max="9999" min="2020"
            [(ngModel)]="dateYear"
            (focus)="setFocused()" (blur)="removeFocused()"
        />
    </mat-form-field>
    <mat-form-field style="margin-top: 0; width: 55px;margin-left: -1px;">
        <input matInput type="number" #hours style="width: 24px;"
            [(ngModel)]="dateHour"
            (focus)="setFocused()" (blur)="removeFocused()"
        />
        <div class="divider">:</div>
    </mat-form-field>
    <mat-form-field style="margin-top: 0; width: 55px;margin-left: -1px;">
        <input matInput type="number" #minutes style="width: 24px;"
            [(ngModel)]="dateMinute"
            (focus)="setFocused()" (blur)="removeFocused()"
        />
    </mat-form-field>
    <mat-form-field style="margin-top: 0; width: 55px;margin-left: -1px;">
        <input matInput style="width: 44px;" readonly
            [value]="twelveHours"
            (keyup)="onKeyUpAMPM( $event )" (click)="onClickAMPM( $event )" (keydown)="onKeyDownAMPM( $event )"
            (focus)="setFocused()" (blur)="removeFocused()"
        />
    </mat-form-field>
    <mat-form-field style="margin-top: 0;width: fit-content;margin-left: -1px;">
        <input matInput readonly tabindex="-1"
            (focus)="setFocused()" (blur)="removeFocused()"
        />
        <mat-icon matSuffix (click)="picker.open()">calendar_today</mat-icon> 
        <input type="hidden" [matDatepicker]="picker" [(ngModel)]="dateForPicker" />
    </mat-form-field>
    <mat-datepicker #picker></mat-datepicker><!-- [startAt]="minDate" -->
</div>