<mat-card appearance="outlined" class="center"> 
  <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading && !isLoadingTokens"></mat-progress-bar>
  <mat-card-content>
    <mat-form-field style="display:block;">
      <mat-label>Demo Example</mat-label>
      <!-- <input matInput [(ngModel)]="token" required /> -->
      <mat-select [(ngModel)]="selectedSubscription" required [disabled]="isLoadingTokens" (selectionChange)="tokenSelected()">
        <ng-container *ngFor="let item of demoSubscriptions">
          <mat-option [value]="item">
            {{ item.Name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <!-- <a color="primary" class="link" (click)="setDefaultToken($event)">Click for default!</a> -->
    <button mat-raised-button color="primary" (click)="login($event)" style="display:block;" [disabled]="isLoading || !token || !pov">Login</button>
    <mat-checkbox [(ngModel)]="keep" style="display:block;">Keep me logged in</mat-checkbox>
    <mat-error *ngIf="errors">{{ errors }}</mat-error>
  </mat-card-content>
</mat-card>
