export const environment = {
  production: false,
  settingsServiceBasePath: 'https://api.dev.smartobjx.com/settings-dev', //https://settings.dev.smartobjx.com/api',
  publicBasePath: 'https://api.dev.smartobjx.com/public',
  ruleServiceBasePath: 'https://api.dev.smartobjx.com/rules-dev',
  structureServiceBasePath: 'https://api.dev.smartobjx.com/structures-dev',
  SubcriptionService:'https://so-preproduction-subscriptionmanagement-east.azurewebsites.net/',

  logins: [
    { name: "SaaS publisher", oid: "45ef2936-160e-47dc-bd17-3dc0060acec9" },
    { name: "Client", oid: "25ef2936-160e-47dc-bd17-3dc0060acec9" },
    { name: "Customer", oid: "05ef2936-160e-47dc-bd17-3dc0060acec9" },
    { name: "Client 1", oid: "a301761e-8fcd-4b6c-b387-8a92ca3af4cb" },
    { name: "Client 1.1", oid: "8d73df9e-e8fc-4030-9941-57445a5ec289" },
    { name: "Client 1.1.1", oid: "117d7b8c-7463-465f-a9ae-1bf5a49d1635" },
    { name: "Client 2", oid: "b5649953-1a26-4ac9-867a-0e4cf9becbd2" },
    { name: "Client 2.1", oid: "09b774df-f706-41d4-9b16-09a4fd69483d" },
    { name: "Client 2.1.1", oid: "f540740a-a922-4beb-bbc0-ad4c613c28e3" },
    { name: "Client 3", oid: "faee5b69-f343-403d-9e83-c3e7cbb26413" },
    { name: "Client 3.1", oid: "2ea8315d-02d0-4c8e-bf29-b317c07a63bf" },
    { name: "Client 3.1.1", oid: "1733d011-111d-4aed-bbfb-742265e206f9" }
  ]
};
