<mat-card
    appearance="outlined"
    class="full-height mat-main-card"
    [class.mat-card-raised]="isActive">
    <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>
    
    <mat-card-title>
        <span class="primary">Profiles</span>
        
        <mat-form-field class="search-bar" *ngIf="!isEmpty && model">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput
                [(ngModel)]="currentSearch"
                placeholder="Search"
                (keyup)="searchPreview( $event, searchInput )"
                #searchInput
            />
      
        </mat-form-field>
    </mat-card-title>

    <!-- on empty list -->
    <mat-card-content *ngIf="useCasesError">
        <div class="h4">Something went wrong...</div>
        <span>{{ useCasesError }}</span>
    </mat-card-content>

    <mat-card-content *ngIf="isEmpty">
        <div style="text-align: center;">
            <mat-icon svgIcon="use_cases_empty" class="use-cases-empty"></mat-icon>
        </div>
        <div class="h4">This section is empty</div>
        <div class="h6">Start creating profiles</div>
    </mat-card-content>

    <mat-card-content *ngIf="!isEmpty && model" class="main-content" style="padding: 0;">
        <span>This window will show all the current applications.</span>

        <mat-card-content> <!--style="padding: 0;"-->
            <mat-list *ngIf="showFilterMessage">
                <mat-list-item class="title">
                    <span class="text">{{ showFilterMessage==='active' ? 'ACTIVE': 'DISABLED' }} USE CASES</span>
                    <button mat-icon-button (click)="this.filterUseCases('all'); showFilterMessage=null;" matTooltip="Remove filter">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>

            <div *ngIf="searchChips.length">
                <mat-chip-listbox #chipList>
                    <span>Search results for:&nbsp;</span>
                    <mat-chip-option
                        *ngFor="let chip of searchChips"
                        (removed)="removeChip(chip)"
                        (click)="removeChip(chip)"
                    >
                        {{ chip }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>
            <div *ngIf="useCasesFull.length && useCasesFiltered.length === 0" class="no-results-filter">
                <div style="text-align: center;">
                    <mat-icon svgIcon="dead_end" class="dead-end"></mat-icon>
                </div>
                <div class="h4">No matching use cases found.</div>
                <div class="h6">Please make sure your keywords are spelled correctly.</div>
                <a (click)="clearSearch()" color="primary" class="link">Reset search</a>
            </div>

            <card-list [(model)]="useCasesFiltered"
                (onEdit)="onEdit( $event )"
                (onDelete)="onDelete( $event )"
                [sortAlphabetically]="true"
                [showDelete]="authService.CanAdd"
                [selectedIndex]="selectedUseCaseIndex"
                showVersions="true"
                (onDebug)="onDebug( $event )" [showDebug]="false"
                (onVersions)="onVersions( $event.record, $event.date, $event.versionDates )"
                sortBy="Name"
                [highlightWords]="searchList"
            >
            </card-list>
        </mat-card-content>
    </mat-card-content>

    
    <mat-card-actions *ngIf="!isLoading" fxFlexAlign="end" align="end">
        <mat-divider></mat-divider>

        <button mat-raised-button
            color="primary"
            class="toolbar-bottom-button"
            (click)="this.getUseCases()"
        >{{ (useCasesError ? 'Try again' : 'Refresh') }}</button>

        <button mat-raised-button *ngIf="!useCasesError && authService.CanAdd"
            color="primary"
            class="toolbar-bottom-button"
            (click)="onCreate()"
            >Add Profile</button>
    </mat-card-actions>
</mat-card>