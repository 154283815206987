import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApplicationService,  PersonService,  ProfilesService,  RulesService,  SettingsService,} from "@smartobjx/smart.connectors";
import { AuthService } from "../authentication/auth.service";
import { tap, concatMap, map } from "rxjs/operators";
import { ViewControllerMember,  ViewControllerService,} from "../view/ViewControllerService";
import { AbstractConfiguration,  Application,  Configuration,  Organization,  Person,  Profile,  SecureSetting,  Setting,} from "@smartobjx/smart.objx.models";
import { v4 as Guid } from "uuid";
import { Tools } from "src/app/shared/Tools";
import { from, forkJoin } from "rxjs";
import { CustomValidator } from "src/app/shared/validation";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
export function factory() {
  return (
    _server: SettingsService,
    _serverRules: RulesService,
    _auth: AuthService,
    _serverApplication: ApplicationService,
    _profilesService: ProfilesService,
    _personService: PersonService,
    _sb: MatSnackBar
  ): Mediator => {
    return new Mediator(
      _server,
      _serverRules,
      _auth,
      _serverApplication,
      _profilesService,
      _personService,
      _sb
    );
  };
}

@Injectable({
  providedIn: "root",
})
export default class Mediator {
  constructor(
    _server: SettingsService,
    _serverRules: RulesService,
    private auth: AuthService,
    _serverApplication: ApplicationService,
    _profilesService: ProfilesService,
    _personService: PersonService,

    private _info: MatSnackBar
  ) {
    this.profilesServer = _profilesService;
    this.personServer = _personService;
    this.serverApplication = _serverApplication;
    this.server = _server;
    this.serverRules = _serverRules;
  }
  profilesServer: ProfilesService;
  personServer: PersonService;
  public serverApplication: ApplicationService;
  private server: SettingsService;
  private serverRules: RulesService;
  get info(): MatSnackBar {
    return this._info;
  }

  showConfigVersions(
    record: any,
    date: Date,
    versionDates: any[],
    callback: () => any,
    viewController: ViewControllerService,
    currentView: ViewControllerMember
  ) {
    const observable = new Observable((subscriber) => {
      let viewRef = viewController.showVersions(
        date,
        versionDates,
        currentView,
        null,
        subscriber
      );
      if (CustomValidator.is(record, "setting")) {
        this.findConfigOn(viewRef, record.OID, date).toPromise();
      } else {
        this.findConfigOnWithVersionInfo(
          viewRef,
          CustomValidator.is(record, "application")
            ? record.ConfigId
            : record.OID,
          date
        ).toPromise();
      }
    });

    observable.subscribe({
      error: (e) => console.error(e),
      complete: callback,
    });
  }

  findConfigOn(
    view: ViewControllerMember,
    configID: string,
    date: Date
  ): Observable<AbstractConfiguration> {
    view.Loading = true;
    date = CustomValidator.fixDate(date);
    date = CustomValidator.fixSecondstoEnd(date);
    let offset = moment.parseZone(date).utcOffset();
    date = moment
      .utc(date)
      .add(-1 * offset, "minutes")
      .format() as any;
    return this.server
      .findConfigOn(configID, date, this.auth.GetSelectedPerspective())
      .pipe(
        tap(
          (abstractConfig: AbstractConfiguration) => {
            view.Loading = false;
            view.updateModel(abstractConfig);
          },
          (e) => {
            view.Loading = false;
          }
        )
      );
  }

  findConfigOnWithVersionInfo(
    view: ViewControllerMember,
    ruleID: string,
    date: Date
  ) {
    view.Loading = true;
    date = CustomValidator.fixDate(date);
    date = CustomValidator.fixSecondstoEnd(date);
    let offset = moment.parseZone(date).utcOffset();
    date = moment
      .utc(date)
      .add(-1 * offset, "minutes")
      .format() as any;
    return this.server
      .findConfigOnWithVersionInfo(
        ruleID,
        date,
        this.auth.GetSelectedPerspective()
      )
      .pipe(
        tap(
          (data: any) => {
            view.Loading = false;
            view.updateModel(data.config as AbstractConfiguration);
            view.updateData({ changedChildren: data.changedChildren });
          },
          (e) => {
            view.Loading = false;
          }
        )
      );
  }

  UpdateEnabledDisabledStates(OID: Guid,state :boolean, date :Date): Observable<any> {
    return this.server.UpdateEnabledDisabledStates(OID , state,date);
  }

  saveConfigurationWithDeleteChildren(
    model: any,
    view: ViewControllerMember,
    model1: any,
    date: Date,
    deleteStack: any[],
    resetForm: () => void,
    viewController: ViewControllerService
  ) {
    date = CustomValidator.fixDate(date);
    date = CustomValidator.fixTimeZero(date);
    let offset = moment.parseZone(date).utcOffset();
    date = moment.utc(date).add(-1 * offset, "minutes").format() as any;
    const useCaseID = viewController.selectedUseCaseID;
    view.Loading = true;
    const observable = forkJoin(
      from(deleteStack).pipe(
        concatMap((o) => {
          let updatedConfig = Object.assign({}, o.configuration, {
            Version: date,
          });
          let call = this.UpdateEnabledDisabledStates(updatedConfig.OID, updatedConfig.Disabled, date);

          return call.pipe(
            map((r) => {
              const i = model.PrimChildren.indexOf(o.configuration);
              if (i > -1) {
               // model.PrimChildren[i].Disabled =  updatedConfig.Disabled
              }
              return r;
            })
          );
        })
      )
    );

    observable.subscribe(() => {
      // after all calls
      this.saveConfiguration(model, model1, view, resetForm, viewController);
    });
  }

  createApplicationFromConfig(
    view: ViewControllerMember,
    model: any,
    resetForm: () => void,
    newApplication: boolean,
    viewController: ViewControllerService
  ) {
    let application: Application = new Application();
    application.Name = model.Name;
    application.Version = model.Version;
    view.Loading = true;
    view.observable.update({ loading: true });
    const applicationId = viewController.selectedUseCaseID;
    model.ApplicationId = applicationId;
    model.Version = CustomValidator.fixDate(model.Version);
    model.Version = CustomValidator.fixTimeZero(model.Version);
    let offset = moment.parseZone(model.Version).utcOffset();
    model.Version = moment
      .utc(model.Version)
      .add(-1 * offset, "minutes")
      .format();
    this.saveConfigWithApplication(
      newApplication ? null : applicationId,
      model
    ).subscribe((config: AbstractConfiguration) => {
      if (config != null) {
        this.saveApplicationDirect(
          config,
          newApplication ? null : applicationId
        ).subscribe((application: Application) => {
          view.observable.resolve({
            name: application.Name,
            OID: application.OID,
          });
          view.updateModel(config);
          this.abstractCallback(config, view, resetForm);
          const version = CustomValidator.ensureDate(config.Version);
          if (version > new Date()) {
            view.updateData({
              versionDateData: {
                date: version,
                showTime: false,
                altClass: false,
              },
            });
          }
          view.updateData({ newApplication: false });
          view.Loading = false;
          resetForm();
        });
      } else {
        throw "something went wrong";
      }
    });
  }

  createProfileFromConfig(
    view: ViewControllerMember,
    model: any,
    resetForm: () => void,
    newProfile: boolean,
    viewController: ViewControllerService
  ) {
    let profile: Profile = new Profile();
    profile.Name = model.Name;
    profile.Version = model.Version;
    view.Loading = true;
    view.observable.update({ loading: true });
    const applicationId = viewController.selectedUseCaseID;
    // model.ApplicationId = applicationId
    console.log(viewController.selectedUseCaseID);
    model.Version = CustomValidator.fixDate(model.Version);
    model.SubscriberID = viewController.selectedUserID;
    model.OwnerID = viewController.selectedUserID;
    console.log(model);
    this.saveConfigWithProfile(model).subscribe(
      (config: AbstractConfiguration) => {
        if (config != null) {
          config.SubscriberID = viewController.selectedUserID;
          config.OwnerID = viewController.selectedUserID;
          this.saveProfileDirect(
            config,
            newProfile ? null : applicationId,
            viewController.selectedUserID
          ).subscribe((application: Application) => {
            // viewController.selectedUseCaseID = newUseCase.OID;
            view.observable.resolve({
              name: application.Name,
              OID: application.OID,
            });
            view.updateModel(config);
            this.abstractCallback(config, view, resetForm);
            const version = CustomValidator.ensureDate(config.Version);
            if (version > new Date()) {
              view.updateData({
                versionDateData: {
                  date: version,
                  showTime: false,
                  altClass: false,
                },
              });
            }
            view.updateData({ newApplication: false });
            view.Loading = false;
            resetForm();
          });
        } else {
          throw "something went wrong";
        }
      }
    );
  }

  saveConfigWithApplication(
    applicationID: string,
    config: AbstractConfiguration
  ): Observable<any> {
    return applicationID
      ? this.server.saveConfiguration(
          applicationID,
          this.auth.GetSelectedPerspective(),
          null,
          config
        )
      : this.server.saveConfiguration(
          "00000000-0000-0000-0000-000000000000",
          this.auth.GetSelectedPerspective(),
          null,
          config
        );
  }
  saveConfigWithProfile(config: AbstractConfiguration): Observable<any> {
    return this.server.saveProfileConfiguration(
      "00000000-0000-0000-0000-000000000000",
      null,
      config
    );
  }
  saveApplicationDirect(config: AbstractConfiguration, applicationId) {
    return this.serverApplication.saveApplication(
      null,
      new Application({
        Name: config.Name,
        ConfigId: config.OID,
        Version: config.Version,
        OID: applicationId,
      })
    );
  }
  saveProfileDirect(config: AbstractConfiguration, applicationId, UserID) {
    return this.profilesServer.saveProfile(
      null,
      new Profile({
        Name: config.Name,
        ConfigId: config.OID,
        Version: config.Version,
        OID: applicationId,
        UserID: UserID,
        SubscriberID: UserID,
        OwnerID: UserID,
      })
    );
  }

  DecryptValue(secureObj: SecureSetting) {
    return this.server.decryptSettingValue(null, secureObj);
  }

  GetDistributionList(oid, name) {
    let headerRequest = {};
    headerRequest["Ocp-Apim-POV-Key"] = this.auth.getPOV();
    headerRequest["Ocp-Apim-Subscription-Key"] = this.auth.getTokenId();
    return this.auth.httpClient.get<Array<any>>(
      `${environment.settingsServiceBasePath}/setting/distribution/${encodeURIComponent(String(oid))}/${encodeURIComponent(String(name))}/perspective/${encodeURIComponent(String(oid))}`,
      {
        withCredentials: null,
        headers: headerRequest,
      }
    );
  }

  GetOrganizationList(guidList: Array<Guid>) {
    let headerRequest = {};
    headerRequest["Ocp-Apim-POV-Key"] = this.auth.getPOV();
    headerRequest["Ocp-Apim-Subscription-Key"] = this.auth.getTokenId();
    return this.auth.httpClient.post<Array<Organization>>(
      `${environment.structureServiceBasePath}/organizationList/`,
      guidList,
      {
        withCredentials: null,
        headers: headerRequest,
      }
    );
  }

  saveConfiguration(
    model: any,
    config: AbstractConfiguration,
    view: ViewControllerMember,
    resetForm: () => void = null,
    viewController: ViewControllerService
  ) {
    let parent = viewController.getParent(model) as any;
    let parentComponent = viewController.getParentComponent(model);
    let findChild;
    if (!_.isNil(parent)) {
      findChild = parent.PrimChildren.find((x) => x.OID == model.OID);
    }
    const i = parent ? parent.PrimChildren.indexOf(findChild) : -1;

    view.Loading = true;
    config.Version = CustomValidator.fixDate(config.Version);
    config.Version = CustomValidator.fixTimeZero(config.Version);
    const isConfiguration = CustomValidator.is(config, "configuration");
    let offset = moment.parseZone(config.Version).utcOffset();
    config.Version = moment
      .utc(config.Version)
      .add(-1 * offset, "minutes")
      .format() as any;
    viewController.showbackdrop.next(true);

    if (Tools.isEmptyGuid(config.OID)) {
      parent.Version = config.Version;
      parent.PrimChildren.push(config);
      let parentView = viewController.getView(parent);
      view.Loading = true;
      view.observable.update({ loading: true });
      this.save(parent, viewController).then((updatedConfig) => {
        if (!_.isNil(parentComponent)) {
          parentComponent.updateConfigurationList();
        } else {
          viewController.showbackdrop.next(true);
        }
        view.observable.resolve({ model: updatedConfig });
        parent = updatedConfig as any;
        view.Loading = false;
        view.close(true);
      });
    } else {
      view.Loading = true;
      if (isConfiguration) {
        this.save(config, viewController).then((updatedConfig) => {
          if (!_.isNil(parentComponent)) {
            parentComponent.updateConfigurationList();
          } else {
            viewController.showbackdrop.next(true);
          }
          if (i >= 0) {
            parent.PrimChildren[i] = updatedConfig;
          }
          if (isConfiguration) {
            resetForm();
            view.Loading = false;
          } else {
            view.close(true);
          }
        });
      } else {
        let custom = config as any;
        this.saveSetting(custom, viewController).then((updatedConfig) => {
          if (!_.isNil(parentComponent)) {
            parentComponent.updateConfigurationList();
          } else {
            viewController.showbackdrop.next(true);
          }
          if (i >= 0) {
            parent.PrimChildren[i] = updatedConfig;
          }
          if (isConfiguration) {
            resetForm();
            view.Loading = false;
          } else {
            view.close(true);
          }
        });
      }
    }
  }

  private async save(
    config: AbstractConfiguration,
    viewController: ViewControllerService
  ): Promise<AbstractConfiguration> {
    let actionMessage = "The changes were saved successfully";
    const ApplicationId = viewController.selectedUseCaseID;
    const configInput = config as Configuration;
    let resp = await this.server
      .saveConfiguration(
        ApplicationId,
        this.auth.GetSelectedPerspective(),
        null,
        configInput
      )
      .toPromise();
    this.info.open(actionMessage, "", {
      duration: 2000,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: "info-primary",
    });
    return resp;
  }

  private async saveSetting(
    config: AbstractConfiguration,
    viewController: ViewControllerService
  ): Promise<AbstractConfiguration> {
    let actionMessage = "The changes were saved successfully";
    const ApplicationId = viewController.selectedUseCaseID;
    const configInput = config as Setting;
    let resp = await this.server
      .saveSetting(this.auth.GetSelectedPerspective(), null, configInput)
      .toPromise();
    this.info.open(actionMessage, "", {
      duration: 2000,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: "info-primary",
    });
    return resp;
  }

  saveConfigurationProfile(
    model: any,
    config: AbstractConfiguration,
    view: ViewControllerMember,
    resetForm: () => void = null,
    viewController: ViewControllerService
  ) {
    let parent = viewController.getParent(model) as any;
    let parentComponent = viewController.getParentComponent(model);
    let findChild;
    if (!_.isNil(parent)) {
      findChild = parent.PrimChildren.find((x) => x.OID == model.OID);
    }
    const i = parent ? parent.PrimChildren.indexOf(findChild) : -1;

    view.Loading = true;
    config.Version = CustomValidator.fixDate(config.Version);

    config.SubscriberID = viewController.selectedUserID;
    config.OwnerID = viewController.selectedUserID;

    const isConfiguration = CustomValidator.is(config, "configuration");
    if (Tools.isEmptyGuid(config.OID)) {
      // fix: on save new child, update parent date
      parent.Version = config.Version;
      parent.PrimChildren.push(config);
      let parentView = viewController.getView(parent);
      view.Loading = true;
      view.observable.update({ loading: true });
      this.saveConfProfile(parent, viewController).then((updatedConfig) => {
        if (!_.isNil(parentComponent)) {
          parentComponent.updateConfigurationList();
        }
        view.observable.resolve({ model: updatedConfig });
        parent = updatedConfig as any;

        view.Loading = false;
        view.close(true);
      });
    } else {
      view.Loading = true;
      this.saveConfProfile(config, viewController).then((updatedConfig) => {
        if (!_.isNil(parentComponent)) {
          parentComponent.updateConfigurationList();
        }
        if (i >= 0) {
          parent.PrimChildren[i] = updatedConfig;
        }
        if (isConfiguration) {
          resetForm();
          view.Loading = false;
        } else {
          view.close(true);
        }
      });
    }
  }

  private async saveConfProfile(
    config: AbstractConfiguration,
    viewController: ViewControllerService
  ): Promise<AbstractConfiguration> {
    let actionMessage = "The changes were saved successfully";
    const ApplicationId = viewController.selectedUseCaseID;
    const configInput = config as Configuration;
    let resp = await this.server
      .saveProfileConfiguration(ApplicationId, null, configInput)
      .toPromise();
    this.info.open(actionMessage, "", {
      duration: 2000,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: "info-primary",
    });
    return resp;
  }
  newConfiguration(
    view: ViewControllerMember,
    parent: AbstractConfiguration,
    startDate: Date,
    parentComponent: any,
    viewController: ViewControllerService
  ) {
    let configuration = new Configuration();
    const now = new Date();
    const version = startDate > now ? startDate : now;
    configuration.Version = version;

    this.newAbstractConfiguration(
      view,
      configuration,
      parent,
      version,
      parentComponent,
      null,
      viewController
    );
  }
  newSetting(
    view: ViewControllerMember,
    parent: AbstractConfiguration,
    startDate: Date,
    parentComponent: any,
    viewController: ViewControllerService
  ) {
    let setting = new Setting();
    const now = new Date();
    const version = startDate > now ? startDate : now;
    setting.Version = version;

    this.newAbstractConfiguration(
      view,
      setting,
      parent,
      version,
      parentComponent,
      null,
      viewController
    );
  }

  newStats(
    view: ViewControllerMember,
    parent: AbstractConfiguration,
    parentComponent: any,
    validationView = false,
    viewController: ViewControllerService
  ) {
    let setting = new Setting();
    const now = new Date();
    const version = now;
    setting.Version = version;

    this.newStatsView(
      view,
      setting,
      parent,
      version,
      parentComponent,
      validationView,
      validationView,
      viewController
    );
  }

  newStatsSpecs(
    view: ViewControllerMember,
    model: Array<Setting>,
    parent: AbstractConfiguration,
    parentComponent: any,
    viewController: ViewControllerService
  ) {
    this.newStatsSpecsView(
      view,
      model,
      parent,
      parentComponent,
      viewController
    );
  }

  editConfiguration(
    configOID: AbstractConfiguration,
    view: ViewControllerMember,
    parentModel: AbstractConfiguration,
    startDate: Date,
    parentComponent: any,
    versionDateData: boolean,
    viewController: ViewControllerService
  ) {
    const now = new Date();
    let version = startDate > now ? startDate : now;
    if (versionDateData) {
      version = startDate;
      // this.server.findConfigOn(configOID, Tools.dateToURLStringAsDate(version)).subscribe((config) => {
      this.newAbstractConfiguration(
        view,
        configOID as Configuration,
        parentModel,
        version,
        parentComponent,
        versionDateData,
        viewController
      );
      // })
    } else {
      //  this.server.findConfigurationWith(configOID).subscribe((config) => {
      this.newAbstractConfiguration(
        view,
        configOID as Configuration,
        parentModel,
        version,
        parentComponent,
        versionDateData,
        viewController
      );
      //  })
    }
  }

  findAllApplication(view: ViewControllerMember): Observable<any> {
    this.serverApplication.configuration.POVToken = this.auth.getPOV();
    this.serverApplication.configuration.SubscriberToken =
      this.auth.getTokenId();

    return this.serverApplication
      .findAllApplication(this.auth.GetSelectedPerspective())
      .pipe(
        tap(
          () => {
            view.Loading = false;
          },
          (e) => {
            view.Loading = false;
          }
        )
      );
  }

  FindProfilesFor(view: ViewControllerMember, user: Guid): Observable<any> {
    this.profilesServer.configuration.POVToken = this.auth.getPOV();
    this.profilesServer.configuration.SubscriberToken = this.auth.getTokenId();
    return this.profilesServer.findProfilesFor(user).pipe(
      tap(
        () => {
          view.Loading = false;
        },
        (e) => {
          view.Loading = false;
        }
      )
    );
  }

  FindPersonWith(user: any): Observable<any> {
    this.personServer.configuration.POVToken = this.auth.getPOV();
    this.personServer.configuration.SubscriberToken = this.auth.getTokenId();
    return this.personServer.findPersonWith(user).pipe(
      tap(
        () => {},
        (e) => {}
      )
    );
  }

  findAllPerson(view: ViewControllerMember): Observable<Array<Person>> {
    this.personServer.configuration.POVToken = this.auth.getPOV();
    this.personServer.configuration.SubscriberToken = this.auth.getTokenId();
    return this.personServer.findAllPerson().pipe(
      tap(
        () => {
          view.Loading = false;
        },
        (e) => {
          view.Loading = false;
        }
      )
    );
  }
  findConfigurationsForApplicationWith(oid: Guid) {
    return this.server.findConfigurationsForApplicationWithId(oid);
  }

  private newAbstractConfiguration(
    view: ViewControllerMember,
    model: Configuration | Setting,
    parent: AbstractConfiguration,
    startDate: Date,
    parentComponent: any,
    versionDateData: any = null,
    viewController: ViewControllerService
  ) {
    const observable = new Observable((subscriber) => {
      viewController.editConfigurationWithParent(
        { model, parent, subscriber, startDate, parentComponent },
        versionDateData
      );
    });

    observable.subscribe({
      next(data: any) {
        // custom this part to separate every call (update and resolve)
        if (data.loading) {
          view.Loading = true;
        } else {
          view.updateModel(data.model);
        }
      },
      error: (e) => {
        console.error(e);
        view.Loading = false;
      },
      complete: () => (view.Loading = false),
    });
  }
  private newStatsView(
    view: ViewControllerMember,
    model: Configuration | Setting,
    parent: AbstractConfiguration,
    startDate: Date,
    parentComponent: any,
    versionDateData: any = null,
    validationView = false,
    viewController: ViewControllerService
  ) {
    const observable = new Observable((subscriber) => {
      viewController.showStats(
        model,
        parent,
        subscriber,
        parentComponent,
        validationView
      );
    });

    observable.subscribe({
      next(data: any) {
        // custom this part to separate every call (update and resolve)
        if (data.loading) {
          view.Loading = true;
        } else {
          view.updateModel(data.model);
        }
      },
      error: (e) => {
        console.error(e);
        view.Loading = false;
      },
      complete: () => (view.Loading = false),
    });
  }

  private newStatsSpecsView(
    view: ViewControllerMember,
    model: Array<Setting>,
    parent: AbstractConfiguration,
    parentComponent: any,
    viewController: ViewControllerService
  ) {
    const observable = new Observable((subscriber) => {
      viewController.showSpecs(model, parent, subscriber, parentComponent);
    });

    observable.subscribe({
      next(data: any) {
        // custom this part to separate every call (update and resolve)
        if (data.loading) {
          view.Loading = true;
        } else {
          view.updateModel(data.model);
        }
      },
      error: (e) => {
        console.error(e);
        view.Loading = false;
      },
      complete: () => (view.Loading = false),
    });
  }

  deleteApplicationWith(
    appId: Guid,
    view: ViewControllerMember
  ): Observable<any> {
    this.serverApplication.configuration.POVToken = this.auth.getPOV();
    this.serverApplication.configuration.SubscriberToken =
      this.auth.getTokenId();
    return this.serverApplication.deleteApplicationWith(appId).pipe(
      tap(
        () => {
          view.Loading = false;
          this.findAllPerson(view);
        },
        (e) => {
          view.Loading = false;
        }
      )
    );
  }

  newApplication(
    view: ViewControllerMember,
    callback: (name: string, oid: string) => void,
    parentComponent: any,
    viewController: ViewControllerService
  ) {
    let observable = new Observable((subscriber) => {
      let viewRef = viewController.showNewApplicationForm(
        true,
        subscriber,
        parentComponent
      ); // show ruleset and drop all other views
      if (viewRef !== undefined && viewRef !== null) viewRef.Loading = false;
    });

    observable.subscribe({
      next(data: any) {
        // custom this part to separate every call (update and resolve)
        if (data.loading) {
          view.Loading = true;
        } else {
          callback(data.name, data.OID);
        }
      },
      error: (e) => {
        console.error(e);
        view.Loading = false;
      },
      // complete: () => view.Loading = false // we want to wait the callback
    });
  }

  newPerson(
    view: ViewControllerMember,
    callback: (name: string, oid: string) => void,
    parentComponent: any,
    viewController: ViewControllerService
  ) {
    let observable = new Observable((subscriber) => {
      let viewRef = viewController.showNewPersonForm(
        true,
        subscriber,
        parentComponent
      ); // show ruleset and drop all other views
      if (viewRef !== undefined && viewRef !== null) viewRef.Loading = false;
    });

    observable.subscribe({
      next(data: any) {
        // custom this part to separate every call (update and resolve)
        if (data.loading) {
          view.Loading = true;
        } else {
          callback(data.name, data.OID);
        }
      },
      error: (e) => {
        console.error(e);
        view.Loading = false;
      },
      // complete: () => view.Loading = false // we want to wait the callback
    });
  }

  private abstractCallback(
    config: AbstractConfiguration,
    view: ViewControllerMember,
    resetForm: () => void
  ) {
    if (config != null) {
      view.updateConfigurationList();
      view.Loading = false;
      resetForm();
    } else {
      view.Loading = false;
      throw "something went wrong";
    }
  }

  updateConfiguration(
    model: AbstractConfiguration,
    viewController: ViewControllerService,
    versionDateData: boolean,
    viewMember: ViewControllerMember
  ): Observable<AbstractConfiguration> {
    if (versionDateData) {
      return this.findConfigOn(viewMember, model.OID, model.Version);
    } else {
      return viewController.SetProfile
        ? this.server.findConfigurationWithoutSubscriber(model.OID)
        : this.server.findConfigurationWith(
            model.OID,
            this.auth.GetSelectedPerspective()
          );
    }
  }

  removeCacheItem(OID: Guid) {
    return this.server.removeCacheItem(OID);
  }

  editApplication(
    application: Application,
    clear: () => void,
    update: () => void,
    next: (app) => void,
    parentComponent,
    viewController: ViewControllerService
  ) {
    const observable = new Observable((subscriber) => {
      let viewRef = viewController.showNewApplicationForm(
        false,
        subscriber,
        parentComponent
      );
      if (!viewRef) {
        subscriber.error();
        return;
      }
      this.findConfiguration(viewRef, application.ConfigId).subscribe({
        next,
        complete: update,
        error: (e) => {
          this.findConfigurationWith(viewRef, application.ConfigId).subscribe({
            next,
            complete: () => {
              update();
              viewRef.showVersionDate();
            },
          });
        },
      });
    });

    observable.subscribe({
      error: (e) => {}, // on error just keep waiting
      complete: clear,
    });
  }

  editProfile(
    application: Application,
    clear: () => void,
    update: () => void,
    next: (app) => void,
    parentComponent,
    viewController: ViewControllerService
  ) {
    const observable = new Observable((subscriber) => {
      let viewRef = viewController.showNewApplicationForm(
        false,
        subscriber,
        parentComponent
      );
      if (!viewRef) {
        subscriber.error();
        return;
      }
      this.findProfileConfiguration(viewRef, application.ConfigId).subscribe({
        next,
        complete: update,
        error: (e) => {
          this.findConfigurationProfileWith(
            viewRef,
            application.ConfigId
          ).subscribe({
            next,
            complete: () => {
              update();
              viewRef.showVersionDate();
            },
          });
        },
      });
    });

    observable.subscribe({
      error: (e) => {}, // on error just keep waiting
      complete: clear,
    });
  }

  reloadConfiguration(
    application: Application,
    clear: () => void,
    update: () => void,
    next: (app) => void,
    parentComponent,
    viewController: ViewControllerService
  ) {
    var viewRef = viewController.Views.find(
      (x) =>
        !_.isNil(x.ParentComponent) &&
        x.ParentComponent.i_NameApplication == application.Name
    );
    this.findConfiguration(viewRef, application.ConfigId, true).subscribe({
      next,
      complete: update,
      error: (e) => {
        this.findConfigurationWith(viewRef, application.ConfigId).subscribe({
          next,
          complete: () => {
            update();
            viewRef.showVersionDate();
          },
        });
      },
    });
  }

  findConfiguration(
    view: ViewControllerMember,
    applicationId: string,
    replaceChildren = false
  ) {
    view.Loading = true;
    return this.server
      .findConfigurationWith(applicationId, this.auth.GetSelectedPerspective())
      .pipe(
        tap(
          (config: Configuration) => {
            view.Loading = false;
            view.updateModel(config, replaceChildren);
          },
          (e) => {
            view.Loading = false;
          }
        )
      );
  }

  findProfileConfiguration(
    view: ViewControllerMember,
    applicationId: string,
    replaceChildren = false
  ) {
    view.Loading = true;
    return this.server.findConfigurationWithoutSubscriber(applicationId).pipe(
      tap(
        (config: Configuration) => {
          view.Loading = false;
          view.updateModel(config, replaceChildren);
        },
        (e) => {
          view.Loading = false;
        }
      )
    );
  }

  findConfigurationWith(view: ViewControllerMember, ruleID: string) {
    view.Loading = true;
    return this.server
      .findConfigurationWith(ruleID, this.auth.GetSelectedPerspective())
      .pipe(
        tap(
          (abstractRule: AbstractConfiguration) => {
            let config = abstractRule as Configuration;
            config.Version = CustomValidator.ensureDate(config.Version);
            view.Loading = false;
            view.updateModel(config);
          },
          (e) => {
            view.Loading = false;
          }
        )
      );
  }

  findConfigurationProfileWith(view: ViewControllerMember, ruleID: string) {
    view.Loading = true;
    return this.server.findConfigurationWithoutSubscriber(ruleID).pipe(
      tap(
        (abstractRule: AbstractConfiguration) => {
          let config = abstractRule as Configuration;
          config.Version = CustomValidator.ensureDate(config.Version);
          view.Loading = false;
          view.updateModel(config);
        },
        (e) => {
          view.Loading = false;
        }
      )
    );
  }

}
