<mat-card appearance="outlined" class="full-height mat-main-card" [class.mat-card-raised]="isActive">
  <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>

  <mat-card-title class="overline">DISTRIBUTION STATS</mat-card-title>
  <mat-icon class="close" (click)="this.view.close()">close</mat-icon>
  <ng-container>
    <mat-card-content>

      <ngx-datatable [rows]="gridData" [loadingIndicator]="loading" class="material striped" [columnMode]="ColumnMode.force" [headerHeight]="50"
        [footerHeight]="50" [rowHeight]="50" (activate)="ShowDetail($event)">
        <ngx-datatable-column prop="Value" [cellClass]="'ValueColumn'"></ngx-datatable-column>
        <ngx-datatable-column prop="Count"></ngx-datatable-column>
        <ngx-datatable-column prop="Percent"></ngx-datatable-column>

      </ngx-datatable>

      <br>
      <mat-divider></mat-divider>
      <br>
      <h4>Chart</h4>
      <ngx-charts-bar-horizontal
      [view]="views"
      [scheme]="colorScheme"
      [results]="chartData"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)"
      >
    </ngx-charts-bar-horizontal>

    </mat-card-content>
    <!-- footer -->



  </ng-container>



</mat-card>