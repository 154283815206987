import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// #region material

// #endregion
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { RuleDialogComponent } from '../rule-dialog/rule-dialog.component';
import { CardListComponent } from '../card-list/card-list.component';
import { CustomDateComponent } from '../custom-date/custom-date.component';
import { VersionCaptionComponent } from '../version-caption/version-caption.component';
import { SettingAdmComponent } from '../setting-set-adm/setting-set-adm.component';
import { ConfigurationEditorComponent } from '../configuration-editor/configuration-editor.component';
import { SettingEditorComponent } from '../setting-editor/setting-editor.component';
import { ConfigurationNotReplaceableComponent } from '../configuration-not-replaceable/configuration-not-replaceable.component';
import { ConfigVersionsComponent } from '../config-versions/config-versions.component';
import { PersonBrowserComponent } from '../person-browser/person-browser.component';
import { ApplicationBrowserComponent } from '../use-case-browser/application-browser.component';
import { ProfileBrowserComponent } from '../profile-browser/profile-browser.component';
import { DistributionStatsComponent } from '../distribution-stats/distribution-stats.component';
import { DistributionSpecsComponent } from '../distribution-specs/distribution-specs.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UploadFileComponent } from '../upload-file/upload-file.component';
import { SmartComponentsModule } from '@smartobjx/smart.components';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';

const routes: Routes = [];
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTableModule,
    MatTreeModule,
    MatRadioModule,
    MatGridListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSidenavModule,
    MatChipsModule,
    MatAutocompleteModule,
    DragDropModule,
    SmartComponentsModule,
    RouterModule.forRoot(routes, {}),
    NgxDatatableModule,
    NgxChartsModule,
    NgxFileDropModule,


  ],
  declarations: [
    VersionCaptionComponent,
    ApplicationBrowserComponent,
    PersonBrowserComponent,
    ProfileBrowserComponent,
    SimpleDialogComponent,
    RuleDialogComponent,
    CardListComponent,
    CustomDateComponent,
    ConfigurationEditorComponent,
    ConfigurationNotReplaceableComponent,
    SettingEditorComponent,
    DistributionStatsComponent,
    DistributionSpecsComponent,
    ConfigVersionsComponent,
    SettingAdmComponent,
    UploadFileComponent
  ]
})
export class StandardModule { }