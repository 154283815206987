import { Injectable } from "@angular/core";
import { SettingsService } from "@smartobjx/smart.connectors";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import {
  ViewControllerMember,
  ViewControllerService,
} from "../view/ViewControllerService";
import { Tools } from "src/app/shared/Tools";
import { AbstractConfiguration } from "@smartobjx/smart.objx.models";
import { CustomValidator } from "src/app/shared/validation";
import { AuthService } from "../authentication/auth.service";
import * as moment from "moment";

export function factory() {
  return (
    _server: SettingsService,
    _viewController: ViewControllerService,
    _auth: AuthService
  ): Mediator => {
    return new Mediator(_server, _viewController, _auth);
  };
}

@Injectable({
  providedIn: "root",
})
export default class Mediator {
  constructor(
    private server: SettingsService,
    private viewController: ViewControllerService,
    private auth: AuthService
  ) {}


  changeVersion(rule: AbstractConfiguration, date: Date) {
  }

  findConfigOn(
    view: ViewControllerMember,
    configID: string,
    date: Date
  ): Observable<AbstractConfiguration> {
    view.Loading = true;
    date = CustomValidator.fixDate(date);
    date = CustomValidator.fixSecondstoEnd(date);
    let offset = moment.parseZone(date).utcOffset();
    date = moment
      .utc(date)
      .add(-1 * offset, "minutes")
      .format() as any;
    return this.server
      .findConfigOn(configID, date, this.auth.GetSelectedPerspective())
      .pipe(
        tap(
          (abstractConfig: AbstractConfiguration) => {
            view.Loading = false;
            view.updateModel(abstractConfig);
          },
          (e) => {
            view.Loading = false;
          }
        )
      );
  }

  editRule(
    parent: AbstractConfiguration,
    selectedDate: Date,
    showTime: boolean,
    isNotInitialDate: boolean,
    callback: null
  ) {
    let rule = Tools.createCopy(parent);
    let date = CustomValidator.ensureDate(selectedDate);

    const observable = new Observable((subscriber) => {
      this.viewController.clearBy(parent);
      // find this rule with version
      let viewRef = this.prepareByType(rule);
      let data = {
        versionDateData: {
          date,
          showTime: showTime,
          altClass: isNotInitialDate,
        },
        subscriber,
      };
      viewRef.replaceData(Object.assign({}, viewRef.Data, data));
      this.findConfigOn(viewRef, parent.OID, date).toPromise();
    });

    observable.subscribe({
      error: (e) => console.error(e),
      complete: callback,
    });
  }

  private prepareByType(
    config: AbstractConfiguration,
    parent?: AbstractConfiguration
  ) {
    if (CustomValidator.is(config, "setting")) {
      return this.viewController.showSetting(config, parent);
    } else if (CustomValidator.is(config, "configuration")) {
      return this.viewController.showConfiguration(config, parent);
    }
  }
}
