import { Component, OnInit } from '@angular/core';
import { MarketServiceConfiguration } from '@smartobjx/smart.connectors';
import { Perspective, Subscription } from '@smartobjx/smart.objx.models';
import { AuthService } from '../core-services/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Mediator from '../core-services/mediator/login-portal.mediator';
import { environment } from '../../environments/environment';
import { ViewControllerService } from '../core-services/view/ViewControllerService';

let serviceConfig: MarketServiceConfiguration = new MarketServiceConfiguration({
  basePath: 'http://localhost/smart.Market.Service'
});

@Component({
  selector: 'app-login-portal',
  templateUrl: './login-portal.component.html',
  styleUrls: ['./login-portal.component.scss']
})
export class LoginPortalComponent implements OnInit {
  errors
  pov: any;
  private _token: string;
  keep: boolean;
  selectedSubscription: any;
  dataOwners: any;
  demoSubscriptions: Subscription[];
  token: string;
  showPerspectives = false
  perspectives: any;
  selectedPerspective: Perspective;
  showOwner = false;
  constructor(
    private authService: AuthService,
    private mediator: Mediator,
    private viewController: ViewControllerService,
    private route: ActivatedRoute,
    private _router: Router) { }
  //#region 
  ngOnInit() {
    this.viewController.SetProfile = false
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        if (!_.isNil(params) && !_.isNil(params.token)) {
          this._router.navigate(
            ['/registerSubscription'],
            { queryParams: { offerMarket: params.token } }
          );
        }
      })
    console.log("test Deploy 16112022")
    this.isLoadingTokens = true
    this.mediator.GetTokensforSettings("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", "BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849")
      .subscribe((subs: any) => {
        this.demoSubscriptions = subs;
        this.isLoadingTokens = false
      })
  }

  //#endregion

  perspectiveSelected() {
    this.isLoading = true
    this.getNestedClients(this.selectedPerspective.OID);
  }
  changeShowPerspectives() {
    if (!_.isNil(this.selectedSubscription) && this.selectedSubscription.AuthTokens.length > 0) {
      this.tokenSelected()
    }
  }
  tokenSelected() {
    this.token = this.selectedSubscription.AuthTokens[0].OID
    this.isLoading = true
 

    this.mediator.GetPerspectives(this.selectedSubscription.AuthTokens[0].OID, this.selectedSubscription.OID).subscribe((perspectives) => {
      this.showPerspectives = true
      this.perspectives = _.map(perspectives, (x: any) => { return { Name: x.Name, OID: x.OID } }).reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.OID === current.OID)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      this.isLoading = false
    })

  }
  private getNestedClients(OID) {
    this.mediator.GetNestedClients("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", this.selectedSubscription.AuthTokens[0].OID, OID)
      .subscribe(orgs => {
        this.showOwner = true
        this.isLoading = false; this.dataOwners = orgs;
      }, err => {
        if (err.status == 404) {
        }
      });
  }

  login(event) {
    if (this.token != null && this.pov != null) {
      this.hasErrors = false;
      this.authService.setTokenId(this.token);
      this.authService.setPOV(this.pov.OID.toString());

      this.authService.userName = null;
      this.authService.ownerName = null
      this.isLoading = true;
      this.mediator.checkLoginAndGetName(this.pov.OID.toString(), this.token, _.isNil(this.selectedPerspective) ? this.pov.OwnerID.toString() : this.selectedPerspective.OID)
        .subscribe({
          next: (sub: any) => {
            this.authService.userName = sub.name;
            this.authService.CanAdd = sub.canAdd
            this.authService.ownerName = this.pov.Name
            this.authService.PerspectiveName = this.selectedPerspective.Name
          },
          error: e => {
            console.error(e);
            this.isLoading = false;
            this.hasErrors = true;
          },
          complete: () => {
            this.isLoading = false;
            localStorage.setItem('perspectiveSelected', _.isNil(this.selectedPerspective) ? this.pov.OwnerID.toString() : this.selectedPerspective.OID);

            if (this.keep) {
              localStorage.setItem('authToken', this.token);
              localStorage.setItem('authPov', this.pov.OID.toString());
            }
            this._router.navigate(['asd']);
          }
        });
    }
  }




  isLoading: boolean = false;
  isLoadingTokens: boolean = true;
  disablePOVs: boolean = true;
  hasErrors: boolean = false;
  errorMessage: string = 'Error: check the data and try again.';
  tokens: any = [];
}