import { SettingsServiceConfiguration } from '@smartobjx/smart.connectors';
import { AuthService } from './authentication/auth.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

export function settingsConfigurationService() {
  return (_authenticationService: AuthService): SettingsServiceConfiguration => {
    return new SettingsServiceConfiguration({
      httpClient: _authenticationService.http,
      basePath: environment.settingsServiceBasePath,
      SubscriberToken: _authenticationService.getTokenId(),
      POVToken: _authenticationService.getPOV()
    });
  };
}