import {  StructuresServiceConfiguration } from '@smartobjx/smart.connectors';
import { AuthService } from './authentication/auth.service';
import { environment } from '../../environments/environment';

export function structureConfigurationService() {
  return (_authenticationService: AuthService): StructuresServiceConfiguration=> {
    return new StructuresServiceConfiguration({
      httpClient: _authenticationService.http,
      basePath: environment.structureServiceBasePath,
      SubscriberToken: _authenticationService.getTokenId(),
      POVToken: _authenticationService.getPOV()
    });
  };
}