<div class="caption version" [class.previous]="isNotInitialDate">
  <ng-container *ngIf="showTime;else with_time">
      <div>You are {{ actionText }} the version active on {{ selectedDate | date: 'MM/dd/yyyy \'at\' HH:mm' }}</div>
  </ng-container>
  <ng-template #with_time>
      <div>You are {{ actionText }} the version active on {{ selectedDate | date: 'MM/dd/yyyy' }}</div>
  </ng-template>
  <smartobjx-version-selector *ngIf="useDatepicker"
      text="change date"
      [selectedDate]="selectedDate"
      [initialDate]="initialDate"
      [versionDates]="versionDates"
      (onChange)="onChangeSelectedDate( $event )"
      [minDate]="firstVersionsDate"
  ></smartobjx-version-selector>
</div>