<mat-card appearance="outlined" class="full-height mat-main-card" [class.mat-card-raised]="isActive">
  <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>

  <mat-card-title class="overline">EDIT {{  asApplication ? 'APPLICATION':'CONFIGURATION' }}</mat-card-title>
  <mat-icon class="close" (click)="this.view.close()">close</mat-icon>
  <ng-container *ngIf="!isLoading">
    <mat-card-content>
      <version-caption *ngIf="versionDateData" [selectedDate]="versionDateData.date"
        [showTime]="versionDateData.showTime" [isNotInitialDate]="versionDateData.altClass"></version-caption>
      <form [formGroup]="form">
        <mat-form-field class="custom">
          <mat-label>Name</mat-label>

          <input matInput  formControlName="name" #nameInput />
        </mat-form-field>
        <mat-form-field class="custom"  >
          <mat-label>Description</mat-label>
          <input matInput formControlName="description" #nameInput />
        </mat-form-field>
      <!--  <mat-hint [innerHTML]="descriptionHint"></mat-hint>

        
        <mat-hint>0000 characters</mat-hint>-->
        <custom-date [(model)]="i_startDate" [(forceInvalid)]="startDateForceInvalid"></custom-date>
        <!-- list of child rules -->
        <ng-container *ngIf="!this.isNew">
          <mat-card-title class="rules-title">
            <span style="    line-height: 48px;">Settings</span>
            <span class="toolbar-spacer"></span>
            <a [matMenuTriggerFor]="add" color="primary" class="link" *ngIf="_authService.CanAdd">+ Add</a>
            <mat-menu #add [overlapTrigger]="false">
              <button mat-menu-item (click)="appendSetting()">Setting</button>
              <button mat-menu-item (click)="appendConfig()">Configuration</button>

            </mat-menu>
  
          </mat-card-title>
        
          <div *ngIf="model.PrimChildren && model.PrimChildren.length == 0" class="h5 no-children">No configurations defined</div>
        </ng-container>
      </form>

      <ng-container *ngIf="!this.isNew && model">
     
        <card-list [(model)]="model.PrimChildren" (onEdit)="appendAbstract( $event )" (onDelete)="onDelete( $event )" (onEnable)="onEnable( $event )"
          (onDebug)="onDebug( $event )" [showDebug]="false" [showDelete]="_authService.CanAdd" (onStats)="showStats($event)"
          (onVersions)="onVersions( $event.record, $event.date, $event.versionDates )" (onChanges)="onChanges()"
          [selectedIndex]="selectedIndex" [forceRuleStatus]="disableOrEnableStack" showType="true" [pov]="pov"
          [showVersions]="true" hideOptions="true" showStats="true"></card-list>
      </ng-container>
    
    </mat-card-content>
    <!-- footer -->
    <mat-card-actions fxFlexAlign="end" align="end">
      <mat-divider></mat-divider>
      <form [formGroup]="form">
          <mat-checkbox
              formControlName="notReplaceable"
              color="accent"
              class="check-no-override"
          >Do not override this config</mat-checkbox>
      </form>
      <button mat-raised-button type="button" color="primary" class="toolbar-bottom-button" [disabled]="formIsDisabled"
        (click)="this.onSaveModel()">Save configuration</button>
    </mat-card-actions>
  </ng-container>

  <!-- POP UPS -->
  <ng-template #startDateMinVerification>
    <div class="h6 start-date-message" matDialogTitle>
      <mat-icon>warning</mat-icon>
      <div class="text">Attention</div>
    </div>
    <mat-dialog-content class="start-date-message">
 

          The chosen Start date has already passed. <br />
          Do you want to make these changes active immediately?

  </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose (click)="fixStartDateAndSave()">Yes</button>
      <button mat-raised-button matDialogClose color="primary" (click)="markStartDateAsDirty()">No</button>
    </mat-dialog-actions>
  </ng-template>
</mat-card>