import { Configuration, Setting } from "@smartobjx/smart.objx.models";
import { CustomValidator } from "./validation";

export class Tools {
  static createCopy(data: any) {
    let config;
    if (CustomValidator.is(data, "configuration")) {
      config = new Configuration(data);
    } else if (CustomValidator.is(data, "setting")) {
      config = new Setting(data);
    }
    return config;
  }
  static getType(data: any) {
    let type;
    if (CustomValidator.is(data, "configuration")) {
      type = "configuration";
    } else if (CustomValidator.is(data, "setting")) {
      type = "setting";
    }
    return type;
  }
  static padZero(n: string, len: number = 2): string {
    return ("0".repeat(len) + n).slice(-len);
  }
  static dateToURLString(date: Date, fix: boolean = true): string {
    if (typeof date === "string") return date;
    let day: string = this.padZero(date.getUTCDate().toString());
    let month: string = this.padZero((date.getUTCMonth() + 1).toString());
    let year = date.getUTCFullYear();
    let hour: string = this.padZero(date.getUTCHours().toString());
    let minutes: string = this.padZero(date.getUTCMinutes().toString());
    let seconds: string = fix
      ? "59"
      : this.padZero(date.getUTCSeconds().toString());
    let milliseconds: string = fix
      ? "999"
      : this.padZero(date.getUTCMilliseconds().toString(), 3);

    return encodeURIComponent(
      `${year}-${month}-${day}T${hour}:${minutes}:${seconds}.${milliseconds}`
    );
  }
  static dateToURLStringAsDate(date: Date, fix: boolean = true): Date {
    // @ts-ignore: Force cast string to Date - unknown is not available at TS2
    return this.dateToURLString(date, fix) as unknown as Date;
  }
  static isEmptyGuid(oid: string) {
    if (oid) {
      return oid === "00000000-0000-0000-0000-000000000000";
    }
    return true;
  }
  static getIconByType(record: any) {
    switch (CustomValidator.getType(record)) {
      case "configuration":
        return "display_settings";
      case "setting":
        return "settings";
    }
  }

  static isSettingType(record: any) {
    return CustomValidator.getType(record) == "setting";
  }

  static getToolTipType(record: any) {
    switch (CustomValidator.getType(record)) {
      case "configuration":
        return "configuration";
      case "setting":
        return "setting";
    }
  }

  static dateToString(date: Date) {
    return `${date.toLocaleDateString("en-GB")} ${date.toLocaleTimeString(
      "en-GB"
    )}.${("0".repeat(3) + date.getMilliseconds()).slice(-3)}`;
  }

  static toShortDate(d: Date) {
    const date = CustomValidator.ensureDate(d);
    let day: string = this.padZero(date.getUTCDate().toString());
    let month: string = this.padZero((date.getUTCMonth() + 1).toString());
    let year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }
  static isInArray(value: any, array: any) {
    return !this.isNotInArray(value, array);
  }
  static isNotInArray(value: any, array: any) {
    return !~array.indexOf(value);
  }
  static isLeap(y: number): boolean {
    return !(y % 4 || (!(y % 100) && y % 400));
  }
  static getDaysInMonth(m: number, y: number): number {
    return m === 2
      ? y & 3 || (!(y % 25) && y & 15)
        ? 28
        : 29
      : 30 + ((m + (m >> 3)) & 1);
  }
}
