import { Component } from "@angular/core";
import { AuthService } from "../core-services/authentication/auth.service";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { NgxFileDropEntry } from "ngx-file-drop";
import { environment } from "src/environments/environment";

@Component({
  selector: "upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent {
  i_NameApplication: string;
  updateRefresh: any;
  onDestroy$: Subject<boolean> = new Subject();
  constructor(public _dialog: MatDialog, private authService: AuthService) {}

  ReadManifest(body) {
    let headerRequest = {};
    headerRequest["Content-Type"] = "application/json";

    return this.authService.httpClient.post(
      `${environment.settingsServiceBasePath}/manifest/`,
      body,
      {
        withCredentials: null,
        headers: headerRequest,
      }
    );
  }

  onFileDrop(event: NgxFileDropEntry[]): void {
    if (event.length > 0) {
      const file = event[0];
      this.validateAndReadJSON(file);
    }
  }

  private validateAndReadJSON(file: NgxFileDropEntry): void {
    if (file.fileEntry.isFile) {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        console.log(file);
        if (file.type != "application/json") {
          console.log("Invalid file");
          return;
        }
        var reader = new FileReader();
        let self = this;
        reader.onload = function (event) {
          var serilizedJson = event.target.result;
          self
            .ReadManifest(JSON.stringify(serilizedJson))
            .subscribe((x) => console.log(x));
        };
        reader.readAsText(file);
      });
    }
  }
}
