<div class="simple-layout">
    <div style="overflow: auto; position: relative; height: 100%;" #mainView>
        <mat-grid-tile *ngFor="let view of viewController.getViews(); let i = index"
            [class.test-advanced]="view.showViewAsAdvanced()" [@card-movement] style="top: 0; height: 100%; width: 32%;"
            [ngStyle]="{ 'left': 'calc(32% * ' + i + ')' }">
            <span *ngIf="view.Type == 'undefined'">undefined type</span>
            <person-browser *ngIf="view.Type == 'person-browser'" class="md-card-full" [(model)]="useCases"
                [view]="view"></person-browser>
            <profile-browser *ngIf="view.Type == 'profile-browser'" class="md-card-full" [(model)]="view.Model"
                [view]="view"></profile-browser>
            <application-browser *ngIf="view.Type == 'application-browser'" class="md-card-full" [(model)]="useCases"
                [view]="view"></application-browser>
            <configuration-editor *ngIf="view.Type == 'configuration-editor'" class="md-card-full"
                [(model)]="view.Model" [view]="view"></configuration-editor>
            <distribution-stats *ngIf="view.Type == 'distribution-stats'" class="md-card-full" [(model)]="view.Model"
                [view]="view"></distribution-stats>
            <distribution-specs *ngIf="view.Type == 'distribution-specs'" class="md-card-full" [(model)]="view.Model"
                [view]="view"></distribution-specs>
            <setting-editor *ngIf="view.Type == 'setting-editor'" class="md-card-full" [(model)]="view.Model"
                [view]="view"></setting-editor>

            <configuration-not-replaceable *ngIf="view.Type == 'configuration-not-replaceable'" class="md-card-full"
                [(model)]="view.Model" [view]="view">

            </configuration-not-replaceable>


            <config-versions *ngIf="view.Type == 'config-versions'" class="md-card-full" [(model)]="view.Model"
                [view]="view" [versionDates]="view.Data.VersionsDates" [selectedDate]="view.Data.Date">

            </config-versions>




        </mat-grid-tile>
    </div>
</div>

 <div *ngIf="showLoading" class="loadingBackDrop">   <span class="loader"></span>
  </div>


