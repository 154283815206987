import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ViewControllerService } from "../core-services/view/ViewControllerService";
import Mediator from "../core-services/mediator/settings.mediator";

import { Title } from "@angular/platform-browser";
import {  trigger,  transition,  style,  animate,  state,} from "@angular/animations";
import { Person } from "@smartobjx/smart.objx.models";

@Component({
  selector: "setting-set-adm",
  templateUrl: "./setting-set-adm.component.html",
  styleUrls: ["./setting-set-adm.component.scss"],
  animations: [
    trigger("card-movement", [
      transition(":enter", [
        style({ transform: "translateX(-10%)", opacity: 0 }),
        animate("100ms", style({ transform: "translateX(0)", opacity: 1 })),
      ]),
      transition(":leave", [
        style({ transform: "translateX(0)", opacity: 1 }),
        animate("100ms", style({ transform: "translateX(-10%)", opacity: 0 })),
      ]),
    ]),
  ],
})
export class SettingAdmComponent implements OnInit, OnDestroy {
  showLoading: boolean = false;
  // #region Event Handlers
  moveViewToEnd() {
    let win = this.mainViewRef.nativeElement;

    setTimeout(() => {
      win.scrollLeft = win.scrollWidth - win.clientWidth;
    }, 300);
  }
  // #endregion

  // #region Construction & Finalization
  constructor(
    private mediator: Mediator,
    title: Title,
    public viewController: ViewControllerService
  ) {
    title.setTitle("smartObjx - Adm.");
  }

  ngOnInit() {
    this.viewController.showbackdrop.subscribe(
      (show) => (this.showLoading = show)
    );
    this.viewAddedEvent = this.viewController.Events.on("viewAdded", () => {
      this.moveViewToEnd();
    });
  }

  ngOnDestroy() {
    this.viewAddedEvent.unsubscribe();
  }

  ngAfterViewInit() {
    let self = this;
    setTimeout(() => {
      if (this.viewController.SetProfile == true) {
        if (!_.isNil(this.viewController.selectedUserID)) {
          this.mediator
            .FindPersonWith(this.viewController.selectedUserID)
            .subscribe((person: Person) => {
              this.viewController.showProfiles(person);
            });
        } else {
          this.viewController.showPersons();
        }
      } else {
        this.viewController.showApplications();
      }
    });
  }
  // #endregion

  // #region Properties
  viewAddedEvent: any;
  // #endregion

  // #region Data Elements
  @ViewChild("mainView", { static: false }) mainViewRef: any; // to move the view when a new card is added
  // #endregion
}
