import { Injectable } from "@angular/core";
import {  ViewControllerMember,  ViewControllerService,} from "../view/ViewControllerService";
import { Observable } from "rxjs";
import { UseCase, AbstractConfiguration } from "@smartobjx/smart.objx.models";
import { Tools } from "src/app/shared/Tools";
import { tap } from "rxjs/operators";

import { RulesService } from "@smartobjx/smart.connectors";

export function factory() {
  return (_server: RulesService, _vc: ViewControllerService): Mediator => {
    return new Mediator(_server, _vc);
  };
}

@Injectable({
  providedIn: "root",
})
export default class Mediator {
  constructor(
    private server: any,
    private viewController: ViewControllerService
  ) {}

  getUseCases(view: ViewControllerMember): Observable<UseCase[]> {
    view.Loading = true;
    return this.server.findAllUseCases().pipe(
      tap(
        () => {
          view.Loading = false;
        },
        (e) => {
          view.Loading = false;
        }
      )
    );
  }

  findRuleOnWithVersionInfo(
    view: ViewControllerMember,
    ruleID: string,
    date: Date
  ) {
    view.Loading = true;
    return this.server
      .findRuleOnWithVersionInfo(ruleID, Tools.dateToURLStringAsDate(date))
      .pipe(
        tap(
          (data: any) => {
            view.Loading = false;
            view.updateModel(data.rule as AbstractConfiguration);
            view.updateData({ changedChildren: data.changedChildren });
          },
          (e) => {
            view.Loading = false;
          }
        )
      );
  }

  findRuleOn(view: ViewControllerMember, ruleID: string, date: Date) {
    view.Loading = true;
    return this.server
      .findRuleOn(ruleID, Tools.dateToURLStringAsDate(date))
      .pipe(
        tap(
          (abstractRule: AbstractConfiguration) => {
            view.Loading = false;
            view.updateModel(abstractRule);
          },
          (e) => {
            view.Loading = false;
          }
        )
      );
  }
  findRule(view: ViewControllerMember, ruleID: string) {
    view.Loading = true;
    return this.server.findRuleWith(ruleID).pipe(
      tap(
        (abstractRule: AbstractConfiguration) => {
          view.Loading = false;
          view.updateModel(abstractRule);
        },
        (e) => {
          view.Loading = false;
        }
      )
    );
  }

  deleteUseCaseWith(name: string): Promise<any> {
    return this.server.deleteUseCaseWith(name).toPromise();
  }
  disableUseCaseWith(name: string): Promise<any> {
    return this.server.disableUseCase(name).toPromise();
  }
  enableUseCaseWith(name: string): Promise<any> {
    return this.server.enableUseCase(name).toPromise();
  }
}
