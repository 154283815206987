import {  Component,  Input,  OnInit,  OnDestroy,  ViewChild,  EventEmitter,} from "@angular/core";
import {  UntypedFormBuilder,  Validators,  UntypedFormGroup,  UntypedFormControl,} from "@angular/forms";
import Mediator from "../core-services/mediator/settings.mediator";
import {  AbstractConfiguration,  Configuration,  Setting,  SecureSetting,} from "@smartobjx/smart.objx.models";
import {  ViewControllerService,  ViewControllerMember,} from "../core-services/view/ViewControllerService";
import { SimpleDialogComponent } from "../simple-dialog/simple-dialog.component";
import { CustomValidator } from "../shared/validation";
import { Tools } from "../shared/Tools";
import { AuthService } from "../core-services/authentication/auth.service";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "setting-editor",
  templateUrl: "./setting-editor.component.html",
  styleUrls: ["./setting-editor.component.scss"],
})
export class SettingEditorComponent implements OnInit, OnDestroy {
  configurations: Configuration;
  updateField: Subject<any>;
  keyEvents: EventEmitter<string> = new EventEmitter();
  constructor(
    private mediator: Mediator,
    public _dialog: MatDialog,
    private viewController: ViewControllerService,
    private fb: UntypedFormBuilder,
    private _authService: AuthService,
    private _info: MatSnackBar
  ) {
    this.minDate = new Date();
    this.advOptEnabled = false;
    this.pov = this._authService.getPOV();
  } // #region Properties

  get model(): Setting | SecureSetting {
    return this.i_Model;
  }

  availableTypes = ["String", "int", "boolean", "long"];

  @Input()
  set model(newModel: Setting | SecureSetting) {
    this.i_Model = newModel;
    this.selectedIndex = -1;
  }

  get selectedIndex(): number {
    return this.i_SelectedIndex;
  }

  set selectedIndex(clickedItemIndex: number) {
    this.i_SelectedIndex = clickedItemIndex;
  }

  get descriptionHint(): string {
    return "This text should be a description of the setting or group of <b>settings</b>. This text should help the administrators to understand what the value affect.";
  }
  get formIsDisabled(): boolean {
    return (
      !this.form ||
      (this.form.pristine && this.disableOrEnableStack.length === 0) ||
      !this.form.valid
    );
  }
  get priorToParent(): boolean {
    const now = new Date();
    return now < this.minDate;
  }

  // the parent date (will be used as minDate)
  get startDate(): Date {
    const { Data } = this.view;
    return Data ? Data.startDate : undefined;
  }
  minDate: Date;
  initialDate: Date; // the current version date
  advOptEnabled: boolean;

  get isNew(): boolean {
    return !!this.model && !this.model.OID;
  }
  private get isUntouched(): boolean {
    return this.form.pristine && this.disableOrEnableStack.length == 0;
  }

  private i_startDate: Date = new Date();
  startDateForceInvalid: boolean = false;
  // #endregion

  // #region Data Elements
  private i_Model: Setting | SecureSetting;
  private i_SelectedIndex: number;
  private form: UntypedFormGroup;
  private disableOrEnableStack: any[] = [];
  private pov: string;

  @Input() view: ViewControllerMember;
  get isLoading(): boolean {
    return this.view.Loading;
  }
  get isActive(): boolean {
    return this.view.Active;
  }
  get asUseCase(): boolean {
    return this.view.asUseCase();
  }
  get versionDateData(): boolean {
    return this.view.Data && this.view.Data.versionDateData;
  }

  @ViewChild("nameInput", { static: false }) nameInputRef: any;
  private disableFocused: boolean = false;
  fixDateValue: string;
  @ViewChild("startDateMinVerification", { static: false })
  startDateMinVerificationDialog: any;
  // #endregion

  // #region Event Emitters
  // #endregion
  // #region Event Handlers

  // #region public
  onSaveModel() {
    if (this.isUntouched) return;
    const date = this.i_startDate;
    if (this.checkDateValues(date)) {
      let model = this.mergeFormToModel();
      const resetForm = () => {
        //this.form.reset();
        this.disableOrEnableStack = [];
      };
      if (this.viewController.SetProfile) {
        this.mediator.saveConfigurationProfile(
          this.model,
          model,
          this.view,
          resetForm,
          this.viewController
        );
      } else {
        this.mediator.saveConfiguration(
          this.model,
          model,
          this.view,
          resetForm,
          this.viewController
        );
      }
    } else {
      this.openDialog(this.startDateMinVerificationDialog);
    }
  }

  openDialog(el: any, options: any = null) {
    return this._dialog.open(el, options);
  }
  checkStartDateBefore(fn: (startDate: Date) => void) {
    const startDate = this.i_startDate;
    if (!startDate) {
      this.fireStartDateError();
      return;
    }

    const now = new Date();
    const version = startDate > now ? startDate : now;

    fn(version);
  }

  fireStartDateError() {
    this._info.open("Start Date must be valid before this action", "", {
      duration: 3000,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: "info-warn",
    });
  }

  onDelete(config: AbstractConfiguration) {
    this.updateSelected(config);
    this.openDialog(SimpleDialogComponent, {
      panelClass: "smart-objx",
      autoFocus: false,
      data: {
        title: "Are you sure?",
        titleClass: "error",
        matIcon: "delete_forever",
        content:
          "Do you really want to want to delete the " +
          this.getType(config) +
          ` "<b>${config.Name}</b>" ?` +
          "\r\nThis process cannot be undone.",
      },
    })
      .afterClosed()
      .toPromise()
      .then((action) => {
        (document.activeElement as any).blur(); // fix force blur on x
        if (action) {
          let primRules = (this.model as any).PrimRules,
            selectedIndex = primRules.indexOf(config);

          primRules.splice(selectedIndex, 1);
          this.viewController.clearBy(this.model);
        }
      });
  }

  onKeyPress(event: KeyboardEvent): void {
    this.keyEvents.emit(event.key);
  }

  DecryptValue() {
    let model = this.model as SecureSetting;
    if (!model.IsEncripted) {
      let encryptedObj = new SecureSetting();
      encryptedObj.Iv = this.form.getRawValue().Iv;
      encryptedObj.Value = this.form.getRawValue().value;
      this.mediator
        .DecryptValue(encryptedObj)
        .subscribe((decryptedValue) =>
          this.form.patchValue({ value: decryptedValue })
        );
    }
  }
  onChanges() {
    (this.form as any).pristine = false;
  }
  checkDateValues(date: Date) {
    return date >= this.minDate;
  }

  toggleAdvancedOptions() {
    this.advOptEnabled = !this.advOptEnabled;
  }

  simpleDate(date: any) {
    return Tools.dateToString(date);
  }
  markStartDateAsDirty() {
    this.startDateForceInvalid = true;
  }
  fixStartDateAndSave() {
    this.i_startDate = this.priorToParent ? this.minDate : new Date();
    this.onSaveModel();
  }
  fixMinDate(date: Date) {
    const diff = this.minDate.getTime() - date.getTime();
    if (diff > 0 && diff < 100) {
      this.minDate = date;
    }
  }
  // #endregion

  // #endregion

  // #region private
  private checkStartDate() {
    const { startDate } = this;
    if (startDate) {
      this.minDate = startDate;
    }
  }
  private updateSelected(config: AbstractConfiguration) {
    this.selectedIndex = (this.configurations as any).PrimChildren.indexOf(
      config
    );
  }

  private getType(record: any) {
    let type = (record as any).$type;
    if (!type) return "undefined";
    if (type.includes("Rules.RuleSet,")) return "ruleset";
    if (type.includes("Rules.Rule,")) return "rule";
  }

  private getControl(name: string): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  private mergeFormToModel() {
    var x = new SecureSetting();
    var y = x as any;
    let model = Object.assign({}, this.model as any);
    model.Name = this.form.getRawValue().name;
    model.Description = this.form.getRawValue().description;
    model.Value = this.form.getRawValue().value;
    model.Type = this.form.getRawValue().type;
    model.Version = this.i_startDate;
    model.NotReplaceable = this.form.getRawValue().notReplaceable;
    model.Iv = this.form.getRawValue().Iv;
    model.$type = !_.isNil(model.Iv) ? y.$type : model.$type;
    model.IsEncripted = undefined;
    return model;
  }

  private stackToDisableOrEnable(data: any) {
    let filter = this.disableOrEnableStack.filter((o) => o.rule === data.rule);
    if (filter.length) {
      const i = this.disableOrEnableStack.indexOf(filter[0]);
      this.disableOrEnableStack.splice(i, 1);
    } else {
      this.disableOrEnableStack.push(data);
    }
  }
  private showWarning() {
    this.openDialog(SimpleDialogComponent, {
      panelClass: "smart-objx",
      autoFocus: false,
      data: {
        title: "Attention",
        titleClass: "warning",
        matIcon: "warning_amber",
        button1Text: "Yes, close the panel",
        button2Text: "No, keep the panel open",
        button1Color: "primary",
        content:
          "Are you sure you want to close this panel?" +
          "\r\nThe changes won´t be saved.",
      },
    })
      .afterClosed()
      .toPromise()
      .then((action) => {
        if (action) {
          this.view.close(true);
        }
      });
  }
  private registerEvents() {
    this.view.Events.onClose$ = () => {
      if (!this.isUntouched) {
        this.showWarning();
      }
      return this.isUntouched;
    };
  }
  // #endregion
  // #endregion

  // #region Construction & Finalization

  ngOnDestroy() {}

  ngOnInit() {
    this.checkStartDate();
    this.registerEvents();
    this.updateField = new Subject();
    let model = this.model as any;
    if (typeof model.Version === "string" && !!model.Version) {
      model.Version = CustomValidator.ensureDate(model.Version);
    }
    this.form = this.fb.group({
      name: [
        { value: model.Name, disabled: !this._authService.CanAdd },
        Validators.required,
      ],
      description: [model.Description, Validators.nullValidator],
      type: [
        { value: model.Type, disabled: !this._authService.CanAdd },
        Validators.required,
      ],
      value: [model.Value, Validators.required],
      notReplaceable: [model.NotReplaceable || false, [Validators.required]],
      Iv: [model.Iv],
    });
    this.i_startDate = new Date(model.Version.getTime());
    this.initialDate = model.Version;
    this.fixMinDate(model.Version);
    this.keyEvents.pipe(debounceTime(1000)).subscribe((event) => {
      this.DecryptValue();
    });
  }
  ngOnChanges(changes: any): void {
    this.checkStartDate();
    if (changes.model && !changes.model.firstChange) {
      let model = changes.model.currentValue;

      if (typeof model.Version === "string" && !!model.Version) {
        model.Version = CustomValidator.ensureDate(model.Version);
      }
      this.form.patchValue({
        name: model.Name,
        description: model.Description,
        value: model.Value,
        type: model.Type,
        notReplaceable: model.NotReplaceable,
        Iv: model.Iv,
      });
      let copyModel = this.model as any;
      this.CheckEncript(copyModel.IsEncripted);
      this.i_startDate = new Date(model.Version.getTime());
      this.initialDate = model.Version;
    }

    // focus name
    if (
      changes.isLoading &&
      changes.isLoading.currentValue == false &&
      !this.disableFocused
    ) {
      setTimeout(() => {
        this.nameInputRef.nativeElement.focus();
        this.disableFocused = true;
      }, 0);
    }
  }
  CheckEncript(Event) {
    console.log(Event);
    this.form.get("Iv").setValidators(Event ? [Validators.required] : []);
    this.form.get("Iv").updateValueAndValidity();
    this.form.patchValue({ Iv: null });
  }

  ShowStats() {
    this.mediator.newStats(
      this.view,
      this.model,
      this,
      null,
      this.viewController
    );
  }
  // #endregion
}
