import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AbstractConfiguration } from "@smartobjx/smart.objx.models";
import Mediator from "../core-services/mediator/config-versions.mediator";
import {
  ViewControllerService,
  ViewControllerMember,
} from "../core-services/view/ViewControllerService";
import { Tools } from "../shared/Tools";
import { CustomValidator } from "../shared/validation";

@Component({
  selector: "config-versions",
  templateUrl: "./config-versions.component.html",
  styleUrls: ["./config-versions.component.scss"],
})
export class ConfigVersionsComponent implements OnInit, OnDestroy {
  // #region Event Handlers
  onChangeSelectedDate(date: Date) {
    this.selectedDate = date;
    this.mediator.changeVersion(this.model, date);
  }

  edit() {
    this.mediator.editRule(
      this.model,
      this.selectedDate,
      this.showTime,
      this.isNotInitialDate,
      null
    );
  }
  showVersionOn() {
    return (record: any) => {
      return record ? !!~this.changedChildren.indexOf(record.OID) : false;
    };
  }

  private getVersionsForDay(d: Date): Date[] {
    return this.versionDates.filter(
      (o) =>
        o.getFullYear() === d.getFullYear() &&
        o.getMonth() === d.getMonth() &&
        o.getDate() === d.getDate()
    );
  }

  // #endregion
  // #region Construction & Finalization
  constructor(
    private viewController: ViewControllerService,
    private mediator: Mediator,
    public _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.initialDate = this.selectedDate;
    if (this.versionDates.length) {
      this.firstVersionsDate = this.versionDates[0];
    }
  }

  ngOnDestroy() {}
  // #endregion

  // #region Properties
  get model(): AbstractConfiguration {
    return this.i_Model;
  }

  @Input()
  set model(newModel: AbstractConfiguration) {
    this.i_Model = newModel;
  }

  get isNotInitialDate(): boolean {
    return this.initialDate.getTime() !== this.selectedDate.getTime();
  }
  get showTime(): boolean {
    return this.getVersionsForDay(this.selectedDate).length > 1;
  }
  get viewType() {
    return Tools.getType(this.model);
  }
  get startDateAsString(): Date {
    return CustomValidator.ensureDate(this.model.Version);
  }

  get changedChildren() {
    return this.view.Data && this.view.Data.changedChildren
      ? this.view.Data.changedChildren
      : [];
  }
  // #endregion

  // #region Data Elements
  private i_Model: AbstractConfiguration;

  @ViewChild("picker", { static: false }) picker: any;

  @Input() view: ViewControllerMember;
  get isLoading(): boolean {
    return this.view.Loading;
  }
  get isActive(): boolean {
    return this.view.Active;
  }

  @Input() selectedDate: Date;
  private initialDate: Date;
  versionTime: Date;
  // demo
  @Input() versionDates: Date[] = [];
  private firstVersionsDate: Date;

  // #endregion

  // #region Events
  // #endregion
}
