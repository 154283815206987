<mat-card appearance="outlined" class="full-height mat-main-card" [class.mat-card-raised]="isActive">
  <mat-progress-bar
    mode="indeterminate"
    class="card-top"
    [class.hidden]="!isLoading"
  ></mat-progress-bar>

  <mat-card-title class="overline">EDIT SETTING</mat-card-title>
  <mat-icon class="close" (click)="this.view.close()">close</mat-icon>
  <ng-container *ngIf="!isLoading">
    <mat-card-content>
      <version-caption
        *ngIf="versionDateData"
        [selectedDate]="versionDateData.date"
        [showTime]="versionDateData.showTime"
        [isNotInitialDate]="versionDateData.altClass"
      ></version-caption>
      <form [formGroup]="form">
        <mat-form-field class="custom">
          <mat-label>Name</mat-label>
          <input
            matInput
            placeholder="Name"
            formControlName="name"
            #nameInput
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Description</mat-label>

          <textarea
            matInput
            placeholder="Description"
            formControlName="description"
          >
          </textarea>
        </mat-form-field>
        <mat-hint [innerHTML]="descriptionHint"></mat-hint>
        <mat-form-field class="custom">
          <mat-label>type</mat-label>

          <input matInput placeholder="type" formControlName="type" />
        </mat-form-field>
        <mat-form-field class="custom">
          <mat-label>value</mat-label>

          <input matInput placeholder="value" formControlName="value" />
        </mat-form-field>

        <mat-checkbox
          class="example-margin"
          [(ngModel)]="this.model.IsEncripted"
          [ngModelOptions]="{ standalone: true }"
          (change)="CheckEncript(model.IsEncripted)"
        >
          Encrypted
        </mat-checkbox>

        <mat-form-field class="custom">
          <mat-label>Initialization Vector</mat-label>

          <input
            matInput
            placeholder="Initialization Vector"
            formControlName="Iv"
            (keypress)="onKeyPress($event)"
            (paste)="onKeyPress($event)"
          />
        </mat-form-field>

        <custom-date
          [(model)]="i_startDate"
          [(forceInvalid)]="startDateForceInvalid"
        ></custom-date>
        <br />
        <div
          style="display: flex; align-items: flex-end; flex-direction: inherit"
        >
          <div>
            <button
              mat-raised-button
              type="button"
              color="primary"
              class="toolbar-bottom-button"
              (click)="ShowStats()"
            >
              Show Distribution
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
    <!-- footer -->
    <mat-card-actions fxFlexAlign="end" align="end">
      <mat-divider></mat-divider>
      <form [formGroup]="form">
        <mat-checkbox
          formControlName="notReplaceable"
          color="accent"
          class="check-no-override"
          >Do not override this setting</mat-checkbox
        >
      </form>
      <button
        mat-raised-button
        type="button"
        color="primary"
        class="toolbar-bottom-button"
        [disabled]="formIsDisabled"
        (click)="this.onSaveModel()"
      >
        Save Setting
      </button>
    </mat-card-actions>
  </ng-container>

  <!-- POP UPS -->
  <ng-template #startDateMinVerification>
    <div class="h6 start-date-message" matDialogTitle>
      <mat-icon>warning</mat-icon>
      <div class="text">Attention</div>
    </div>
    <mat-dialog-content class="start-date-message">
      <ng-container
        *ngIf="asUseCase || !priorToParent; else noUseCaseMessageForStartDate"
      >
        The chosen Start date has already passed. <br />
        Do you want to make these changes active immediately?
      </ng-container>
      <ng-template #noUseCaseMessageForStartDate>
        A Start date prior to the Parent's Start date has been chosen. <br />
        Do you want to inherit the Start date from the Parent?
      </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose (click)="fixStartDateAndSave()">
        Yes
      </button>
      <button
        mat-raised-button
        matDialogClose
        color="primary"
        (click)="markStartDateAsDirty()"
      >
        No
      </button>
    </mat-dialog-actions>
  </ng-template>
</mat-card>
