import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { RuleSet, Application, Person } from "@smartobjx/smart.objx.models";
import {  ViewControllerService,  ViewControllerMember,} from "../core-services/view/ViewControllerService";
import Mediator from "../core-services/mediator/settings.mediator";
import { SimpleDialogComponent } from "../simple-dialog/simple-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { Tools } from "../shared/Tools";
import { AuthService } from "../core-services/authentication/auth.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "person-browser",
  templateUrl: "./person-browser.component.html",
  styleUrls: ["./person-browser.component.scss"],
})
export class PersonBrowserComponent implements OnInit, OnDestroy {
  i_NameApplication: string;
  updateRefresh: any;
  onDestroy$: Subject<boolean> = new Subject();
  i_PersonsFull: Person[];
  i_usePersons: Person[];
  constructor(
    public _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private viewController: ViewControllerService,
    private mediator: Mediator,
    private authService: AuthService
  ) {}
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }
  ngOnInit(): void {
    this.updateRefresh = this.viewController.updateRootForReplace
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getPersons(false);
      });
  }
  //#region Event Handlers
  getPersons(reloadCurrent = false) {
    this.useCasesError = null; // clean errors

    this.mediator.findAllPerson(this.view).subscribe(
      (applications) => {
        this.isEmpty = false;
        if (applications.length == 0) {
          this.isEmpty = true;
        }
        this.PersonsFull = applications;
        this.model = applications;
        if (reloadCurrent) {
          var app = applications.find(
            (appplication) =>
              appplication.Name == this.viewController.nameApplication
          );
          // this.mediator.reloadConfiguration(app, () => this.selectedUseCaseID = null, () => { this.selectedUseCaseID = app.ConfigId; this.selectedNameApplication = app.Name; },
          //     application => {
          //         if (this.viewController.getConfigView(application, "configuration-editor") === "configuration-not-replaceable") {
          //             let view = this.viewController.getView(application);
          //             view.changeType("configuration-not-replaceable");
          //         }
          //     }
          //     , this)
        }
      },
      (error: HttpErrorResponse) => {
        this.useCasesError = error.message;
      }
    );
  }

  onVersions(record: any, date: Date, versionDates: any[]) {
    this.mediator.showConfigVersions(
      record,
      date,
      versionDates,
      () => (this.selectedUseCaseID = null),
      this.viewController,
      this.view
    );
    this.selectedUseCaseID = record.OID;
  }

  filterPerson(type: string) {
    switch (type) {
      case "active":
        this.usePersons = this.PersonsFull.filter((o) => !(o as any).Disabled);
        break;
      case "disabled":
        this.usePersons = this.PersonsFull.filter((o) => (o as any).Disabled);
        break;
      default:
        this.usePersons = this.PersonsFull;
    }
  }

  useCaseCreated(name: string) {
    this._snackBar.open(`Your Use Case ${name} was successfully added.`, "", {
      duration: 2000,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: "info-primary",
    });

    setTimeout(() => {
      this.getPersons();
      this.isEmpty = false;
    }, 500);
  }

  //#region list events

  onCreate() {
    this.mediator.newPerson(
      this.view,
      (name, oid) => {
        this.useCaseCreated(name);
        this.selectedUseCaseID = oid;
      },
      this,
      this.viewController
    );
  }
  onEdit(person: Person) {
    this.viewController.selectedUserID = person.UserID;
    this.viewController.showProfiles(person);
  }

  onDelete(application: Application) {
    this.selectedUseCaseID = application.OID;
    this._dialog
      .open(SimpleDialogComponent, {
        panelClass: "smart-objx",
        autoFocus: false,
        data: {
          title: "Are you sure?",
          titleClass: "error",
          matIcon: "close",
          button1Text: "Yes, delete it",
          content:
            "Do you really want to want to delete the application " +
            `<b>${application.Name}</b> ?`,
        },
      })
      .afterClosed()
      .toPromise()
      .then((action) => {
        (document.activeElement as any).blur();
        this.selectedUseCaseID = null;
        if (action) {
          this.view.Loading = true;
          this.mediator
            .deleteApplicationWith(application.OID, this.view)
            .subscribe(() => {
              this._dialog
                .open(SimpleDialogComponent, {
                  panelClass: "smart-objx",
                  data: {
                    title: "Success!",
                    titleClass: "success",
                    matIcon: "check",
                    hideButton1: true,
                    closeOnSeconds: 3,
                    button2Text: "close",
                    content:
                      "Your application " +
                      `<b>${application.Name}</b>` +
                      " was succefully deleted.",
                  },
                })
                .afterClosed()
                .toPromise();
              this.getPersons();
            });
        }
      });
  }
  //#endregion

  searchPreview(e: any, el: any) {
    if (e.key === "Enter") {
      this.search(el);
    }
    // this.updateAutocomplete( el );
  }
  search(el: any, clean: boolean = true) {
    const value = el.value.trim();
    if (value && Tools.isNotInArray(value, this.searchChips))
      this.searchChips.push(value.toLowerCase());
    if (clean) el.value = "";
  }
  removeChip(value: string) {
    const i = this.searchChips.indexOf(value);
    this.searchChips.splice(i, 1);
  }
  clearSearch() {
    this.searchChips = [];
    this.currentSearch = "";
  }

  // #region Construction & Finalization

  ngAfterViewInit() {
    setTimeout(() => {
      this.getPersons();
    });
  }
  // #endregion

  // #region Properties
  get model(): Person[] {
    return this.i_Model;
  }

  @Input()
  set model(newModel: Person[]) {
    this.i_Model = newModel;
  }

  get selectedUseCaseID(): string {
    return this.i_SelectedUseCaseID;
  }

  set selectedUseCaseID(oid: string) {
    this.i_SelectedUseCaseID = oid;
    if (oid) this.viewController.selectedUseCaseID = oid;
  }
  set selectedNameApplication(name: string) {
    this.i_NameApplication = name;
    if (name) this.viewController.nameApplication = name;
  }

  get selectedUseCaseIndex(): number {
    return this.PersonFiltered
      ? this.PersonFiltered.map((o) => o.OID).indexOf(this.selectedUseCaseID)
      : -1;
  }

  get PersonsFull(): Person[] {
    return this.i_PersonsFull;
  }
  set PersonsFull(newUseCases: Person[]) {
    this.i_PersonsFull = newUseCases;
    this.usePersons = newUseCases;
  }

  get usePersons(): Person[] {
    return this.usePersons;
  }

  set usePersons(newUseCases: Person[]) {
    this.i_usePersons = newUseCases;
  }

  get PersonFiltered(): Person[] {
    return this.searchList.length
      ? this.PersonsFull.filter(
          (o) =>
            this.searchList.filter((c) =>
              Tools.isInArray(c, o.Name.toLowerCase())
            ).length
        )
      : this.PersonsFull;
  }
  get searchList(): string[] {
    const list = this.searchChips.slice();
    if (this.currentSearch) {
      list.push(this.currentSearch.toLowerCase());
    }
    return list;
  }
  private searchChips: string[] = [];
  private currentSearch: string;
  private autocompleteList: string[];

  private i_Model: Person[];
  private i_SelectedUseCaseID: string;
  useCasesError: string;
  debugging: RuleSet;

  @Input() view: ViewControllerMember;
  get isLoading(): boolean {
    return this.view.Loading;
  }
  get isActive(): boolean {
    return this.view.Active;
  }
  isEmpty: boolean = false;

  showFilterMessage: string;
  // #endregion
}
