import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule }    from '@angular/forms';

import { StandardModule } from '../standard/standard.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    StandardModule,
  ],
  declarations: [
  ]
})
export class SettingSetAdmModule {}