import UseCaseMediator, {
  factory as UseCaseMediatorFactory,
} from "./use-case-browser.mediator";
import CardListMediator, {
  factory as CardListMediatorFactory,
} from "./card-list.mediator";
import LoginPortalMediator, {
  factory as LoginPortalMediatorFactory,
} from "./login-portal.mediator";
import ConfigVersionMediator, {
  factory as ConfigVersionMediatorFactory,
} from "./config-versions.mediator";

import FixesMediator, {
  factory as FixesMediatorFactory,
} from "./fixes.mediator";

import SettingsMediator, {
  factory as SettingsMediatorFactory,
} from "./settings.mediator";
import {
  ApplicationService,
  PersonService,
  ProfilesService,
  RulesService,
  SettingsService,
  StructuresService,
} from "@smartobjx/smart.connectors";
import { ViewControllerService } from "../view/ViewControllerService";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../authentication/auth.service";

export function combined() {
  return [
    {
      provide: UseCaseMediator,
      useFactory: UseCaseMediatorFactory(),
      deps: [RulesService, ViewControllerService],
    },
    {
      provide: CardListMediator,
      useFactory: CardListMediatorFactory(),
      deps: [SettingsService, AuthService],
    },
    {
      provide: LoginPortalMediator,
      useFactory: LoginPortalMediatorFactory(),
      deps: [SettingsService, StructuresService, PersonService, AuthService],
    },
    {
      provide: ConfigVersionMediator,
      useFactory: ConfigVersionMediatorFactory(),
      deps: [SettingsService, ViewControllerService, AuthService],
    },

    {
      provide: FixesMediator,
      useFactory: FixesMediatorFactory(),
      deps: [RulesService, AuthService],
    },
    {
      provide: SettingsMediator,
      useFactory: SettingsMediatorFactory(),
      deps: [
        SettingsService,
        RulesService,
        AuthService,
        ApplicationService,
        ProfilesService,
        PersonService,
        MatSnackBar,
      ],
    },
  ];
}
