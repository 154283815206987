import { ProfilesServiceConfiguration } from '@smartobjx/smart.connectors';
import { AuthService } from './authentication/auth.service';
import { environment } from '../../environments/environment';

export function profilesConfigurationService() {
  return (_authenticationService: AuthService): ProfilesServiceConfiguration => {
    return new ProfilesServiceConfiguration({
      httpClient: _authenticationService.httpClient,
      basePath: environment.settingsServiceBasePath,
      SubscriberToken: _authenticationService.getTokenId(),
      POVToken: _authenticationService.getPOV()
    });
  };
}